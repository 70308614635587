import { useTranslation } from "react-i18next"
import Cookies from "universal-cookie"
import { getLang } from "../../helpers/localizationHelper"
import { Navigate, useNavigate } from "react-router"

export const ProfileMenu = () => {
    const { t } = useTranslation()
    const cookies = new Cookies()
    const isAuth = cookies.get('token')
    const navigate=useNavigate()
    return <li className="nav-item has_dropdown">
        <a className="nav-link" href="#"><div className="profile-icon none-mobile"><i className="icofont-user"></i></div>
        {/* <span className="only-mobile">{t('profile')}</span> */}
        </a>
        <span dir={getLang() == "ar-SA" ? "rtl" : "ltr"} className="drp_btn"><i className="icofont-rounded-down"></i></span>
        <div className="sub_menu profile_menu" dir={getLang() == "ar-SA" ? "rtl" : "ltr"}>
            <ul className="w-100">
                {isAuth == undefined ? <>
                    <li><a href={'/login' + `?returnUrl=${window.location.href?.replace(window.location.origin, '')}`}>{t("signIn")}</a></li>
                    <li><a href={'/register' + `?returnUrl=${window.location.href?.replace(window.location.origin, '')}`}>{t("signUp")}</a></li>
                </> : <>
                    <li className="mt-2">
                        <a href="/myProfile" className="profile_menu_item">
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.9848 15.3462C8.11714 15.3462 4.81429 15.931 4.81429 18.2729C4.81429 20.6148 8.09619 21.2205 11.9848 21.2205C15.8524 21.2205 19.1543 20.6348 19.1543 18.2938C19.1543 15.9529 15.8733 15.3462 11.9848 15.3462Z" stroke="var(--primary)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M11.9848 12.0059C14.5229 12.0059 16.58 9.94779 16.58 7.40969C16.58 4.8716 14.5229 2.81445 11.9848 2.81445C9.44667 2.81445 7.38857 4.8716 7.38857 7.40969C7.38 9.93922 9.42381 11.9973 11.9524 12.0059H11.9848Z" stroke="var(--primary)" strokeWidth="1.42857" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            {t("userProfile")}
                        </a>
                    </li>
                    <li className="border-top mt-2"><a className="profile_menu_item" onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        cookies.remove('token', { path: '/' });
                        cookies.remove("refreshToken", { path: '/' });
                        navigate('/home')
                        // window.location.reload()
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="var(--primary)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                        {t("logout")}</a></li>
                </>}
          
            </ul>
        </div>
    </li>
}