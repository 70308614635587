import ComingSoon from "../../components/comingSoon";

const PaymentsScreen = () => {
    return (
        <>
            {/* <div className="row">
                <div className="col-lg-4 col-md-6">
                    <div className="review_box payment-card">
                        <h3>#000123456</h3>
                        <span className="badge badge-rounded badge-outline-success badge-lg">Payment Confirmed</span>
                        <p>Nov 21th 2020 03:45 PM</p>
                        <h3>173.00 SAR</h3>
                        <div className="reviewer mb-3">
                            <div className="avtar">
                                <img src="images/review_01.png" alt="image" />
                            </div>
                            <div className="text">
                                <h3>Restaurant Name</h3>
                                <span>Riyadh tahlia st.</span>
                            </div>
                        </div>
                        <div className="redeem-cta mt-4">
                            <a href="#" className="btn white_btn">View Receipt</a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6">
                    <div className="review_box payment-card">
                        <h3>#000123456</h3>
                        <span className="badge badge-rounded badge-outline-primary badge-lg">Payment Pending</span>
                        <p>Nov 21th 2020 03:45 PM</p>
                        <h3>173.00 SAR</h3>
                        <div className="reviewer mb-3">
                            <div className="avtar">
                                <img src="images/review_02.png" alt="image" />
                            </div>
                            <div className="text">
                                <h3>Restaurant Name</h3>
                                <span>Riyadh tahlia st.</span>
                            </div>
                        </div>
                        <div className="redeem-cta mt-4">
                            <a href="#" className="btn white_btn">View Receipt</a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6">
                    <div className="review_box payment-card">
                        <h3>#000123456</h3>
                        <span className="badge badge-rounded badge-outline-danger badge-lg">Payment Canceled</span>
                        <p>Nov 21th 2020 03:45 PM</p>
                        <h3>173.00 SAR</h3>
                        <div className="reviewer mb-3">
                            <div className="avtar">
                                <img src="images/review_03.png" alt="image" />
                            </div>
                            <div className="text">
                                <h3>Restaurant Name</h3>
                                <span>Riyadh tahlia st.</span>
                            </div>
                        </div>
                        <div className="redeem-cta mt-4">
                            <a href="#" className="btn white_btn">View Receipt</a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6">
                    <div className="review_box payment-card">
                        <h3>#000123456</h3>
                        <span className="badge badge-rounded badge-outline-success badge-lg">Payment Confirmed</span>
                        <p>Nov 21th 2020 03:45 PM</p>
                        <h3>173.00 SAR</h3>
                        <div className="reviewer mb-3">
                            <div className="avtar">
                                <img src="images/review_01.png" alt="image" />
                            </div>
                            <div className="text">
                                <h3>Restaurant Name</h3>
                                <span>Riyadh tahlia st.</span>
                            </div>
                        </div>
                        <div className="redeem-cta mt-4">
                            <a href="#" className="btn white_btn">View Receipt</a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6">
                    <div className="review_box payment-card">
                        <h3>#000123456</h3>
                        <span className="badge badge-rounded badge-outline-primary badge-lg">Payment Pending</span>
                        <p>Nov 21th 2020 03:45 PM</p>
                        <h3>173.00 SAR</h3>
                        <div className="reviewer mb-3">
                            <div className="avtar">
                                <img src="images/review_02.png" alt="image" />
                            </div>
                            <div className="text">
                                <h3>Restaurant Name</h3>
                                <span>Riyadh tahlia st.</span>
                            </div>
                        </div>
                        <div className="redeem-cta mt-4">
                            <a href="#" className="btn white_btn">View Receipt</a>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6">
                    <div className="review_box payment-card">
                        <h3>#000123456</h3>
                        <span className="badge badge-rounded badge-outline-danger badge-lg">Payment Canceled</span>
                        <p>Nov 21th 2020 03:45 PM</p>
                        <h3>173.00 SAR</h3>
                        <div className="reviewer mb-3">
                            <div className="avtar">
                                <img src="images/review_03.png" alt="image" />
                            </div>
                            <div className="text">
                                <h3>Restaurant Name</h3>
                                <span>Riyadh tahlia st.</span>
                            </div>
                        </div>
                        <div className="redeem-cta mt-4">
                            <a href="#" className="btn white_btn">View Receipt</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pagination-con">
                <nav>
                    <ul className="pagination d-flex justify-content-center flex-wrap pagination-rounded-flat pagination-success">
                        <li className="page-item"><a className="page-link" href="#" data-abc="true"><i className="icofont-rounded-left"></i></a></li>
                        <li className="page-item active"><a className="page-link" href="#" data-abc="true">1</a></li>
                        <li className="page-item"><a className="page-link" href="#" data-abc="true">2</a></li>
                        <li className="page-item"><a className="page-link" href="#" data-abc="true">3</a></li>
                        <li className="page-item"><a className="page-link" href="#" data-abc="true">4</a></li>
                        <li className="page-item"><a className="page-link" href="#" data-abc="true"><i className="icofont-rounded-right"></i></a></li>
                    </ul>
                </nav>
            </div> */}
            <ComingSoon />
        </>);
}

export default PaymentsScreen;