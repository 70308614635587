import { t } from "i18next"
import { baseURLPhotos } from "../../apis/endpoints"
import { LanguageMenu } from "../../components/header/languageMenu"
import { ProfileMenu } from "../../components/header/profileMenu"
import { getLang } from "../../helpers/localizationHelper"
import { useNavigate, useParams } from "react-router"
import { Helmet } from "react-helmet"
import { useEffect, useState } from "react"
import { GetReservationDetailsByQR } from "../../apis/restaurants"


const ReservationsDetailsScreen = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    let [detailsByQr, setDetailsByQr] = useState<any>()

    useEffect(() => {
        (async () => {
            const { data } = await GetReservationDetailsByQR({ id })
            setDetailsByQr(data)
        })()

    }, [])
    return (
        <>
            <Helmet>
                <link rel='stylesheet' href='/css/booking-details-style.css' />
            </Helmet>
            <div className="full_bg">
                <div className="container">
                    <section className="signup_section">
                        <div className="d-flex w-100 justify-content-between align-items-baseline my-5">
                            <a dir={getLang() == "ar-SA" ? "rtl" : "ltr"}
                                style={{ cursor: 'pointer' }} onClick={() => {
                                    navigate('/home')
                                }} className="back_btn">
                               {getLang() == "ar-SA" ? <i className="icofont-arrow-right"></i> : <i className="icofont-arrow-left"></i>} 
                                {t("back")}
                            </a>

                            <div dir={getLang() == "ar-SA" ? "rtl" : "ltr"} className='abs-container'>
                                <LanguageMenu />
                            </div>
                        </div>

                        <div className="signup_form reservation_details">
                            <div className="rest-cont">
                                <div className="logo largeLogo">
                                    <img src={`${detailsByQr?.tenantImage ? (baseURLPhotos + "/" + detailsByQr?.tenantImage) : '/images/no-img.jpg'}`} alt="vendor-logo" />
                                </div>
                            </div>
                            <div className="row mt-4 p-4">
                                <div className="text-center col-md-12 mt-2 mb-2">
                                    <h5>{detailsByQr?.tenantName}</h5>
                                    <span>{detailsByQr?.branchName}</span>
                                </div>

                                    <div className="w-100 col-md-6 mt-3">
                                        <div className="rounded py-3 px-2 shadow-sm border d-flex align-items-center justify-content-center"
                                            style={{
                                                color: "#32236F",
                                                width: '100%'
                                            }}
                                            dir={getLang() == 'en-US' ? 'ltr':'rtl'}>
                                            <div className="icon-box icon-box-lg bg-primary-light">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-100" height="25" width="25" viewBox="0 0 512 512">
                                                    <path d="M0 448V64h18v384H0zm26.9-.3V64H36v383.7h-9.1zm27.1 0V64h8.9v383.7H54zm44.9 0V64h8.9v383.7h-8.9zm36 0V64h17.7v383.7h-17.7zm44.9 0V64h8.9v383.7h-8.9zm18 0V64h8.9v383.7h-8.9zm18 0V64h8.9v383.7h-8.9zm35.7 0V64h18v383.7h-18zm44.9 0V64h18v383.7h-18zm36 0V64h18v383.7h-18zm36 0V64h18v383.7h-18zm26.9 0V64h18v383.7h-18zm45.1 0V64h26.9v383.7h-26.9zm35.7 0V64h9.1v383.7H476zm18 .3V64h18v384h-18z" fill="#32236F" />
                                                </svg>
                                            </div>
                                            <div className={`mx-3 w-75 d-flex align-items-start flex-column`}>
                                                <h6 className="font-w600 text-nowrap">
                                                    {t('code')}
                                                </h6>
                                                <span>
                                                    {detailsByQr?.code}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-100 col-md-6 mt-3">
                                        <div className="rounded py-3 px-2 shadow-sm border d-flex align-items-center justify-content-center"
                                            style={{
                                                color: "#32236F",
                                                width: '100%'
                                            }}
                                            dir={getLang() == 'en-US' ? 'ltr':'rtl'}>
                                            <div className="icon-box icon-box-lg bg-primary-light">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" className="w-100" viewBox="0 0 496 512">
                                                    <path d="M225.4 233.4c-12.5 12.5-12.5 32.8 0 45.3 12.5 12.5 32.8 12.5 45.3 0 12.5-12.5 12.5-32.8 0-45.3-12.5-12.5-32.8-12.5-45.3 0zM248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm126.1 148.1L308.2 300.4a31.9 31.9 0 0 1 -15.8 15.8l-144.3 66c-16.7 7.6-33.8-9.6-26.2-26.2l66-144.4a31.9 31.9 0 0 1 15.8-15.8l144.3-66c16.7-7.6 33.8 9.6 26.2 26.2z" fill="#32236F"/>
                                                </svg>
                                            </div>
                                            <div className={`mx-3 w-75 d-flex align-items-start flex-column`}>
                                                <h6 className="font-w600 text-nowrap">
                                                   {t('status')}
                                                </h6>
                                                <span className="text-nowrap">
                                                    {detailsByQr?.reservationStatusName}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-100 col-md-6 mt-3">
                                        <div className="rounded py-3 px-2 shadow-sm border d-flex align-items-center justify-content-center"
                                            style={{
                                                color: "#32236F",
                                                width: '100%'
                                            }}
                                            dir={getLang() == 'en-US' ? 'ltr':'rtl'}>
                                            <div className="icon-box icon-box-lg bg-primary-light">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" className="w-100" viewBox="0 0 448 512">
                                                    <path d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm64-192c0-8.8 7.2-16 16-16h288c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16v-64zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z" fill="#32236F" />
                                                </svg>
                                            </div>
                                            <div className={`mx-3 w-75 d-flex align-items-start flex-column`}>
                                                <h6 className="font-w600 text-nowrap">
                                                    {t('BookingDate')} 
                                                </h6>
                                                <span className="text-nowrap">
                                                    {detailsByQr?.dateSlot}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="w-100 col-md-6 mt-3">
                                        <div className="rounded py-3 px-2 shadow-sm border d-flex align-items-center justify-content-center"
                                            style={{
                                                color: "#32236F",
                                                width: '100%'
                                            }}
                                            dir={getLang() == 'en-US' ? 'ltr':'rtl'}>
                                            <div className="icon-box icon-box-lg bg-primary-light">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" className="w-100" viewBox="0 0 512 512">
                                                    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z" fill="#32236F" />
                                                </svg>
                                            </div>
                                            <div className={`mx-3 w-75 d-flex align-items-start flex-column`}>
                                                <h6 className="font-w600 text-nowrap" dir={getLang() == 'ltr' ? 'ltr':'rtl'}>
                                                    {t('BookingTime')}
                                                     {/* {detailsByQr?.reservationStatusName} */}
                                                </h6>
                                                <span className="text-nowrap">
                                                    {detailsByQr?.timeSlot}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-100 col-md-6 mt-3">
                                        <div className="rounded py-3 px-2 shadow-sm border d-flex align-items-center justify-content-center"
                                            style={{
                                                color: "#32236F",
                                                width: '100%'
                                            }}
                                            dir={getLang() == 'en-US' ? 'ltr':'rtl'}>
                                            <div className="icon-box icon-box-lg bg-primary-light">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" className="w-100" viewBox="0 0 416 512">
                                                    <path d="M207.9 15.2c.8 4.7 16.1 94.5 16.1 128.8 0 52.3-27.8 89.6-68.9 104.6L168 486.7c.7 13.7-10.2 25.3-24 25.3H80c-13.7 0-24.7-11.5-24-25.3l12.9-238.1C27.7 233.6 0 196.2 0 144 0 109.6 15.3 19.9 16.1 15.2 19.3-5.1 61.4-5.4 64 16.3v141.2c1.3 3.4 15.1 3.2 16 0 1.4-25.3 7.9-139.2 8-141.8 3.3-20.8 44.7-20.8 47.9 0 .2 2.7 6.6 116.5 8 141.8 .9 3.2 14.8 3.4 16 0V16.3c2.6-21.6 44.8-21.4 48-1.1zm119.2 285.7l-15 185.1c-1.2 14 9.9 26 23.9 26h56c13.3 0 24-10.7 24-24V24c0-13.2-10.7-24-24-24-82.5 0-221.4 178.5-64.9 300.9z" fill="#32236F" />
                                                </svg>
                                            </div>
                                            <div className={`mx-3 w-75 d-flex align-items-start flex-column`}>
                                                <h6 className="font-w600 text-nowrap">
                                                    {t('partySize')}
                                                </h6>
                                                <span>
                                                    {detailsByQr?.partySize}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-100 col-md-6 mt-3">
                                       
                                        <div className="rounded py-3 px-2 shadow-sm border d-flex align-items-center justify-content-center"
                                            style={{
                                                color: "#32236F",
                                                width: '100%'
                                            }}
                                            dir={getLang() == 'en-US' ? 'ltr':'rtl'}>
                                            <div className="icon-box icon-box-lg bg-primary-light">
                                               
                                                <svg xmlns="http://www.w3.org/2000/svg" height="25" width="25" className="w-100" viewBox="0 0 384 512">
                                                    <path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" fill="#32236F"/>
                                                </svg>
                                            </div>
                                            <div className={`mx-3 w-75 d-flex align-items-start flex-column`}>
                                                <h6 className="font-w600 text-nowrap">
                                                    {t('isWaiting')}
                                                </h6>
                                                <span className="text-nowrap">
                                                    {detailsByQr?.isWaiting ? "" : t('Not')} {t('Waiting')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default ReservationsDetailsScreen;