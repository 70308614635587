
interface RadioButtonProps {
    buttons: Array<buttonProps>
}
interface buttonProps {
    label: string,
    checked?: boolean,
    onChange: () => void,
}

const RadioButtonControl = ({ buttons = [] }: RadioButtonProps) => {

    return <div className="d-flex align-items-center" >
        {!!buttons.length && buttons.map((item, index) => {
            return <div className="d-flex align-items-center" style={{
                marginInlineEnd: 20

            }}>
                <input style={{
                    accentColor: 'rgb(106, 73, 242)',

                }} checked={item.checked} type="radio" onChange={item.onChange} value={`option${index + 1}`} />
                <label style={{
                    marginInlineStart: 20,
                    marginTop: 5
                }}>
                    {item.label}
                </label>
            </div>
        })}

    </div>
}

export default RadioButtonControl