import { GetAllLanguages } from "../../apis/lookups";
import { changeLang } from "../../helpers/localizationHelper";

export const changeLanguage = ({ language: language }:{language:any}) => {
  changeLang(language);
  return {
    type: "CHANGE_LANG",
    payload: language,
  };
};

export const getLanguages:any = async () => {
  const res = await GetAllLanguages();
  return {
    type: "GET_LANG",
    payload: res,
  };
};
