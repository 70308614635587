import Cookies from "universal-cookie";
import { getLang } from "../../helpers/localizationHelper";
import { ErrorToast,SuccessToast } from "../../helpers/toastify";
import axiosCall from "./axiosConfig";
import { interceptorsErrorsResponse } from "./interceptorErrorResponseCases";
import moment from "moment";
import { refreshToken } from "../auth";
import { Navigate } from "react-router";

// axiosCall.interceptors.request.use(
//   (req) => {
//     const cookies = new Cookies();
//     req.headers["Accept-Language"] = getLang();
//     req.headers["Authorization"] = cookies.get("token");
//     return req;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );
// axiosCall.interceptors.response.use(
//   (res) => {
//     if (res.config.method == "delete") {
//       SuccessToast(res?.data?.data?.message ?? "تم الحذف بنجاح");
//     } else if (res.config.method == "put" || res.config.method == "patch") {
//       SuccessToast(res?.data?.data?.message);
//     } else if (res.config.method == "post") {
//       if (
//         !res?.config?.url?.includes("Lookup") &&
//         !res?.config?.url?.includes("Get") &&
//         !res?.config?.url.includes("Login") &&
//         !res?.config?.url.includes("Dashboard")
//       ) {
//         if (res?.data?.message != '') {
//           SuccessToast(res?.data?.message);
//         }
//       }
//     }
//     return res;
//   },
//   async (error) => {
//     const originalConfig = error.config;
//     const cookies = new Cookies();
//     if (originalConfig.url !== '/Auth/Login' && error.response) {
//         if (error.response.status == 401 && originalConfig.url !== '/Auth/RefreshToken') {
//           try {
//             const resp = await refreshToken(cookies.get("refreshToken"));
//             if(resp.status){
//             cookies.set("token", `Bearer ${resp.data?.token}`, { expires: moment(resp.data.expiresOn).toDate(), path: "/" })
//             cookies.set("refreshToken", `${resp?.data.refreshToken}`)
//             cookies.set("tokenExpires", moment(resp.data.expiresOn).toDate())
//             axiosCall.defaults.headers.common["Authorization"] = `Bearer ${resp.data?.token}`;
//             return axiosCall(originalConfig);
//             }
//           } catch (_error) {
//             return Promise.reject(_error);
//           }
//         } else {
//           const err = { ...error }
//           if (!error?.response?.config?.url?.includes("Get")) {
//             if (err?.response?.status == 401 && originalConfig.url == '/Auth/RefreshToken') {
//               window.location.reload();
//               <Navigate to={'/'} />;
//             } else {
//               interceptorsErrorsResponse(err);
//             }
//           }
//           return err
//         }
//       }
//       else{
//         const err = { ...error }
//         if (!error?.response?.config?.url?.includes("Get")) {
//           if (err?.response?.status == 401) {
//             return ErrorToast(err?.response?.data?.message);
//           } else {
//             interceptorsErrorsResponse(err);
//           }
//         }
//         return err
//       }
//   }
// );

var isLoading = false
// Create a list to hold the request queue
const refreshAndRetryQueue = [];
axiosCall.interceptors.request.use(
  (req) => {
    const cookies = new Cookies();
    req.headers["Accept-Language"] = getLang();
    req.headers["Authorization"] = cookies.get("token");
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosCall.interceptors.response.use(
  (res) => {
    if (res.config.method == "delete") {
      SuccessToast(res?.data?.data?.message ?? "تم الحذف بنجاح");
    } else if (res.config.method == "put" || res.config.method == "patch") {
      SuccessToast(res?.data?.data?.message);
    } else if (res.config.method == "post") {
      if (
        !res?.config?.url?.includes("Lookup") &&
        !res?.config?.url?.includes("Get") &&
        !res?.config?.url.includes("Login") &&
        !res?.config?.url.includes("Dashboard")
      ) {
        if (res?.data?.message != '') {
          SuccessToast(res?.data?.message);
        }
      }
    }
    return res;
  },
  async (error) => {
    const originalConfig = error.config;
    const cookies = new Cookies();
    if (originalConfig.url !== '/Auth/Login' && error.response) {
      if (error.response.status == 401 && originalConfig.url !== '/Auth/RefreshToken') {
         if(!isLoading){
        isLoading = true;
          try {
           const resp = await refreshToken(cookies.get("refreshToken"));
            if (resp.status) {
              cookies.set("token", `Bearer ${resp.data?.token}`, { expires: moment(resp.data.expiresOn).toDate(), path: "/" })
              cookies.set("refreshToken", `${resp?.data.refreshToken}`, { expires: moment(resp?.data.refreshTokenExpiration).toDate(), path: "/" })
              axiosCall.defaults.headers.common["Authorization"] = `Bearer ${resp.data?.token}`;
              refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                axiosCall
                  .request(config)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              });
              // Clear the queue
              refreshAndRetryQueue.length = 0;
              return axiosCall(originalConfig);
            }
          } catch (_error) {
            throw _error;
          } finally {
            isLoading = false;
          }
        }
      return new Promise((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalConfig, resolve, reject });
      });
      } else {
        const err = { ...error }
        if (!error?.response?.config?.url?.includes("Get")) {
          if (err?.response?.status == 401 && originalConfig.url == '/Auth/RefreshToken') {
            cookies.remove('token', { path: '/' });
            cookies.remove("refreshToken", { path: '/' });
            window.location.reload();
            <Navigate to={'/'} />;
          } else {
            interceptorsErrorsResponse(err);
          }
        }
        return err
      }
    }
    else {
      const err = { ...error }
      if (!error?.response?.config?.url?.includes("Get")) {
        if (err?.response?.status == 401) {
          return ErrorToast(err?.response?.data?.message);
        } else {
          interceptorsErrorsResponse(err);
        }
      }
      return err
    }
  }
);


