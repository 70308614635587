import { useTranslation } from "react-i18next"
import { RatingComponent } from "../../../components/ratingComponent"
import { useState } from "react";
import { submitSurvey } from "../../../apis/survey";
import { useNavigate } from "react-router";
import { Loader } from "../../../components/loader";

export const ReviewComponent = ({ survey, code }: { code: any, survey?: any }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [flag, setFlag] = useState(false);
    const [notes, setNotes] = useState('')
    const [starSelected, setStarSelected] = useState([]);
    const [isSuccessful, setSuccess] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const handleRatingState = (index: any, val: any, item: any) => {
        setFlag(true);
        const tempStars: any = [...starSelected];
        tempStars[index] = {
            "surveyQuestionId": item?.id,
            "rateValue": val,
            "notes": ""
        };
        setStarSelected(tempStars);
        starSelected?.map(async (item: any) => {
            if (item?.value == 0) {
                await setFlag(false);
            }
        });
    };
    return <form id="review-form" className="signup-form">
        {isSuccessful == false ? <>
            {survey?.surveyQuestions?.length > 0 ? <>
                <h2 className="form-title">{t("How was your experience?")}</h2>
                {survey?.surveyQuestions?.map((item: any, index: any) => <div key={index}
                    style={{ display: 'flex', flexDirection: 'column', width: "100%", alignItems: 'flex-start', marginBlock: 15 }}>
                    <p style={{ marginBlock: 2, fontSize: 20 }}><span style={{ marginInlineEnd: 10, color: 'red', }}>*</span>{item?.text}</p>
                    <RatingComponent setRate={(val: any) => {
                        handleRatingState(index, val, item);
                    }} tooltipText={t("Your rate")}
                        tooltipArray={[t("Terrible"), t("Poor"), t("Good"), t("Great"), t("Fantastic"),]} />
                    <div style={{ height: 1, width: '100%', opacity: .2, backgroundColor: 'black', marginTop: 20 }}></div>

                </div>)}

                <div>
                    <p style={{ marginBlock: 2, fontSize: 20 }}>{t("notes")}</p>

                    <textarea onChange={(e) => {
                        setNotes(e.target.value)
                    }} className="form-control" rows={5} id="comment1"  ></textarea>
                </div>

                <div className="form-group mt-3">
                    <div className="btn form-submit d-block h8 " onClick={async () => {
                        if (starSelected?.length == survey?.surveyQuestions?.length) {
                            setLoading(true)
                            const res = await submitSurvey({
                                data: {
                                    code: code,
                                    notes: notes,
                                    surveyResponseQuestions: starSelected
                                }

                            })
                            if (res?.status) {
                                setSuccess(true)
                            }
                            setLoading(false)

                        }
                    }}>{t("SUBMIT")}</div>

                </div></> : survey?.surveyQuestions?.length == 0 || survey?.surveyQuestions == null || survey == null ?
                <><h2 className="form-title mt-5">{survey}
                    <div className="btn form-submit d-block h8 mt-3" onClick={async () => {
                        navigate('/home')
                    }}>{t("back_to_home")}</div>
                </h2>
                </> : <Loader />}
        </> : <>
            <h2 className="form-title">{t("successful_survey")}</h2>
            <div className="successful-icon"><img src="/images/success.gif" alt="vendor-logo" /></div>
            <p className="textmuted h5 mb-2 text-center">{t("You successfully created your survey")}</p>
        </>}
        {isLoading && <Loader />}
    </form>
}