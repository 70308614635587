import { useTranslation } from "react-i18next"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { getLang } from "../../../helpers/localizationHelper";
import { baseURLPhotos } from "../../../apis/endpoints";
export const RestaurantsSection = ({ allRestaurants }: { allRestaurants: any }) => {


    const { t } = useTranslation()
    return <section className="row_am" >
        <div className="container">
            <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <h2>{t("Trusted by")} <span>{t("the best")}</span> {t("restaurants")}</h2>
            </div>


            <Swiper
                lang={getLang() == 'ar-SA' ? "ar" : "en"}
                dir={getLang() == 'ar-SA' ? "rtl" : 'ltr'}
                data-aos="fade-up" data-aos-duration="2000" data-aos-delay="100"
                slidesPerView={5}


                pagination={{
                    clickable: true,
                }}
                spaceBetween={15}
                modules={[Pagination]}
                className="mySwiper mt-5"
                breakpoints={{
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                    },
                    450: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                    },
                    1200: {
                        slidesPerView: 5,
                        spaceBetween: 30,
                    },
                }}
            >
                {allRestaurants?.length ? <>
                    {allRestaurants?.map((item: any, index: any) => {
                        return <div key={item.id}>
                            <SwiperSlide key={item.id}>
                                <a href={`/booking/bookingDetails/${item?.id}`} className={'partners-images'}>
                                    <img style={{ objectFit: 'contain' }} src={item?.logoImage ? (baseURLPhotos + "/" + item?.logoImage) : '/images/logo-preloader.png'} alt="image" />
                                    <p >{item?.title}</p>
                                </a>
                            </SwiperSlide>

                        </div>


                    })}
                </> : <div style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 10 }}>
                    <img width={50} src={'/images/logo-preloader.png'} alt="image" />
                    <p style={{ margin: 0, padding: 0 }}>{t("noRestaurants")}</p>

                </div>}

            </Swiper>

            {/* <div id="company_slider" className="owl-carousel owl-theme">
                    {allRestaurants?.map((item: any) => {
                        return <div className="item">
                            <div className="logo">
                                <a target="_blank" ><img src={'/noImage.png'} alt="image" /></a>
                            </div>
                        </div>
                    })}

                </div> */}

        </div>
    </section>

}