import { toast } from "react-toastify";
import { getLang } from "./localizationHelper";

export const ErrorToast = (message) => {
  toast.error(`${message}`, {
    toastId: "error1",
    rtl: getLang() == "ar-SA" ? true : false,

    position: getLang() == "ar-SA" ? "bottom-left" : "bottom-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const SuccessToast = (message) => {
  toast.success(`${message}`, {
    rtl: getLang() == "ar-SA" ? true : false,

    toastId: "success1",
    position: getLang() == "ar-SA" ? "bottom-left" : "bottom-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
