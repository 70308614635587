import { Navigate } from "react-router";
import axiosCall from "../axios/axiosConfig";
import { endpoints } from "../endpoints";
import Cookies from "universal-cookie";

export const loginAPI = ({ email, password }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.auth.login, {
        email,
        password
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            token: res?.data?.obj?.token,
            tokenTime: res?.data?.obj?.expiresOn,
            refreshToken:res?.data?.obj?.refreshToken,
            refreshTokenTime:res?.data?.obj?.refreshTokenExpiration
          });
        } else {
          reject(res?.data?.message);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const registerAPI = (data) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.auth.register, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            token: res?.data?.obj?.token,
            time: res?.data?.obj?.expiresOn,
          });
        } else {
          reject(res?.data?.message);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const refreshToken = (refreshToken) => {
  return new Promise((resolve,reject)=>{
    axiosCall.post(endpoints.auth.refreshToken,{
      refreshToken:refreshToken
    }).then((res)=>{
      if (res?.data?.code == 200) {
        resolve({
          status: true,
          message: res?.data?.message,
          data: res?.data?.data || res?.data?.obj,
        });
      } else {
        // reject(res);
        <Navigate to={'/'} />

      }
    
    }).catch((e)=>{
     reject(e)
    })
  })
}