import axiosCall from "../axios/axiosConfig";
import { endpoints } from "../endpoints";

export const userProfileData = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.userProfile.get)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updateUserProfile = ({ data }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.userProfile.update, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updatePassword = ({ data }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.userProfile.changePassword, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
