import i18next from "i18next"

export const translate = (name:string) => {
  return  i18next.t(name??'')
}

export const changeLang = (lang: string = 'en-US') => {
  localStorage.setItem('lang', lang)
  i18next.changeLanguage(lang)
}
export const getLang=()=>localStorage.getItem('lang')??'en-US'