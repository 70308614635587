import { useTranslation } from "react-i18next"

export const BannerSection = () => {
    const { t } = useTranslation()
    return <section className="banner_section">
        <div className="container">
            <div className="row">
                <span className="banner_shape1"> <img src="/images/banner-shape1.png" alt="image" /> </span>
                <span className="banner_shape2"> <img src="/images/banner-shape2.png" alt="image" /> </span>
                <span className="banner_shape3"> <img src="/images/banner-shape3.png" alt="image" /> </span>
                <div className="col-lg-6 col-md-12" >
                    <div className="banner_text">
                        <h1>{t("bannerTitle")}</h1>
                        <p>{t("bannerQuote")}
                        </p>
                    </div>
                    <ul className="app_btn">
                        <li>
                            <a className="nav-link dark_btn" href={'/contactUs'}>{t("book_now")}</a>
                        </li>
                    </ul>


                </div>

                <div className="col-lg-6 col-md-12"  >
                    <div className="banner_image">
                        <img className="moving_animation" src="/images/banner-image.png" alt="image" />
                    </div>
                </div>

            </div>
        </div>

        <div>
            <svg className="waves" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                <defs>
                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                </defs>
                <g className="parallax">
                    <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                    <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                    <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                    <use href="#gentle-wave" x="48" y="7" fill="#f6f4fe" />
                </g>
            </svg>
        </div>

    </section>
}