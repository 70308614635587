import { useTranslation } from "react-i18next"
import { LanguageMenu } from "../header/languageMenu"
import { ProfileMenu } from "../header/profileMenu"

export const Header = () => {
    const { t } = useTranslation()
    return <header>
        <div className="container">
            <nav className="navbar navbar-expand-lg ">
                <a href="/home" className="navbar-brand">
                    <img src="/images/logo.png" alt="image" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon">
                        <div className="toggle-wrap">
                            <span className="toggle-bar"></span>
                        </div>
                    </span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <div className="navbar-nav d-flex w-100 justify-content-end">
                            <div className="nav-item p-0 m-0">
                                <a className="nav-link" href='/home'>{t("home")}</a>
                            </div>
                            <div className="nav-item">
                                <a className="nav-link" href="/home#features">{t("Features")}</a>
                            </div>
                            <div className="nav-item">
                                <a className="nav-link" href="/home#pricing">{t("pricing")}</a>
                            </div>
                            <div className="nav-item">
                                <a className="nav-link" href="/howItWorks">{t("howItWorks")}</a>
                            </div>
                            <div className="nav-item">
                                <a className="nav-link" href="/contactUs">{t("contact us")}</a>
                            </div>
                            <ProfileMenu />
                            <LanguageMenu />
                        
                        {/*   <li className="nav-item">
                            <a className="nav-link dark_btn" href="contact-ar.html">احجز عرضًا تجريبيًا</a>
                        </li> */}

                    </div>
                </div>
            </nav>
        </div>
    </header>

}