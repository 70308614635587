import { Helmet } from "react-helmet"
import { getLang } from "../../helpers/localizationHelper"
import { useNavigate, useParams } from "react-router"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { detailsRestaurant } from "../../apis/restaurants"
import { resolveRequest } from "../../helpers"
import { ReviewComponent } from "./components/review"
import { getSurveyCode } from "../../apis/survey"
import { useSelector } from "react-redux"
import { baseURLPhotos } from "../../apis/endpoints"

export const SurveyScreen = () => {
    const { code } = useParams()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [restaurantDetails, setRestaurantDetails] = useState<any>()
    const [survey, setSurvey] = useState<any>()

    useEffect(() => {
        resolveRequest(getSurveyCode, setSurvey, { code: code })
    }, [code])
    useEffect(() => {
        if (survey?.status == false) {
            navigate('/*')
        }
    }, [survey])
    const lang = useSelector((state: any) => state?.language?.getLang)

    return <> <Helmet>
        <link rel='stylesheet' href='/css/style-booking.css' />
        <link rel='stylesheet' href='/css/myStyle.css' />
        <link rel="stylesheet" href="/css/material-design-iconic-font.min.css" />
        {/*             <link rel="stylesheet" href="/css/bootstrap@5.1.1_dist_css_bootstrap.min.css" />
*/}            <link rel="stylesheet" href="/css/icofont.min.css" />
        <script src="/js/jquery-3.6.0.min"></script>
        <script src="/js/cdn.jsdelivr.net_npm_bootstrap@5.1.1_dist_js_bootstrap.bundle.min.js"></script>


    </Helmet>
        <a dir={getLang() == "ar-SA" ? "rtl" : "ltr"} onClick={() => {
            navigate("/home")
            window.location.reload()
        }} className="back"> 
        {getLang() == "ar-SA" ? <i className="icofont-arrow-right"></i> : <i className="icofont-arrow-left"></i>} 
        {t("back")}</a>

        <div dir={getLang() == "ar-SA" ? "rtl" : "ltr"} className='abs-container'>
            {/*    <div className='common-lang'>
                <div className="profile-icon"><i className="icofont-user"></i></div>

                    <div dir={getLang() == "ar-SA" ? "rtl" : "ltr"} className='commonBody'>
            {isAuth == undefined ? <>
                <a href="/login">{t("signIn")}</a>
                <a href="/register">{t("signUp")}</a>
            </> : <>
                <a href="/myProfile">{t("myProfile")}</a>
                <a href="/myReservations">{t("myReservations")}</a>

                <a onClick={() => {
                    cookies.remove("token", { path: '/', domain: 'localhost' })
                    setTimeout(() => {
                        window.location.reload()

                    }, 1000);
                }}>{t("logout")}</a></>}
        </div> *


            </div> */}
            <div className='common-lang'>
                <div className="profile-icon "><img src={getLang() == "ar-SA" ? "/images/ar-lang.png" : "/images/en-lang.png"} alt="image" className="menu-flag-select" /></div>
                <span dir={getLang() == "ar-SA" ? "rtl" : "ltr"} className="drp_btn" style={{ marginInlineStart: 8 }}><i className="icofont-rounded-down"></i></span>

                <div dir={getLang() == "ar-SA" ? "rtl" : "ltr"} className='commonBody'>

                    {lang && lang?.map((item: any, index: any) => {
                        return <a onClick={() => {
                            localStorage.setItem("lang", item?.code)
                            setTimeout(() => {
                                window.location.reload()

                            }, 500);

                        }}><img src={item?.code == 'en-US' ? "/images/en-lang.png" : "/images/ar-lang.png"} alt="image" className="menu-flag m-0 ml-2 mr-2" />{item?.title}</a>
                    })}


                </div>

            </div>


        </div>

        <div style={{

            paddingBlock: 135,
            paddingInline: 25,
            backgroundImage: `url(/bg.jpg)`,
            backgroundColor: 'purple',
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: '100vh'
        }}
            className="main" >

            <section className="signup">
                <div className="rest-cont">
                    <div className="logo largeLogo">
                        <img src={`${restaurantDetails?.data?.logoImage ? (baseURLPhotos + "/" + restaurantDetails?.data?.logoImage) : '/images/logo-preloader.png'}`} alt="vendor-logo" />
                    </div>
                </div>
                <div className="container">

                    <div className="signup-content">
                        <ReviewComponent code={code} survey={survey?.data} />
                    </div>
                </div>
                <div className="tableer-footer">
                    <p className="tableer-footer-p">
                        {t("Powered by")}

                    </p>
                    <a href="https://tableer.co" className="tableer-footer-link">
                        <img src="/images/tableer-footer-logo.png" alt="vendor-logo" />
                    </a>
                </div>
            </section>

        </div></>
}