import { t } from "i18next";
import { useNavigate } from "react-router";
import { LanguageMenu } from "../../components/header/languageMenu";
import { getLang } from "../../helpers/localizationHelper";

const ForgetPassword = () => {
    const navigate = useNavigate()

    return (
        <>
            <div className="full_bg">
                <div className="container">
                    <section className="signup_section">
                        <div className="d-flex w-100 justify-content-center align-items-baseline">
                            <div dir={getLang() == "ar-SA" ? "rtl" : "ltr"} className="top_part w-100">
                                <a dir={getLang() == "ar-SA" ? "rtl" : "ltr"}
                                    style={{ cursor: 'pointer' }} onClick={() => {
                                        navigate(-1)
                                    }} className="back_btn">
                                    {getLang() == "ar-SA" ? <i className="icofont-arrow-right"></i> : <i className="icofont-arrow-left"></i>} 
                                    {t("back")}
                                </a>
                                <a className="navbar-brand" href="#">
                                    <img src="/images/footer_logo.png" alt="image" />
                                </a>
                            </div>
                            <div dir={getLang() == "ar-SA" ? "rtl" : "ltr"} className='abs-container'>
                                <LanguageMenu />
                            </div>
                        </div>

                        <div className="signup_form">
                            <div className="section_title">
                                <h2>{t('Reset')} <span>{t('password')}</span> </h2>
                                <p>{t('Enter your email and well send you a link')}
                                    <br /> {t('to get back into your account')}</p>
                            </div>
                            <form action="">
                                <div className="form-group">
                                    <input type="email" className="form-control" placeholder={t("email")} />
                                </div>

                                <div className="form-group">
                                    <button className="btn puprple_btn" type="submit">{t('ResetPassword')}</button>
                                </div>
                            </form>


                        </div>
                    </section>
                </div>

            </div>

        </>
    );
}

export default ForgetPassword;