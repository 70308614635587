export const baseURL = process.env.REACT_APP_BASEURL;
export const baseURLPhotos = process.env.REACT_APP_BASEURL_PHOTOS;

export const endpoints = {
  lookups: {
    languages: "/Lookup/GetAllLanguages",
    countries: "/Lookup/GetAllCountries",
  },
  restaurants: {
    listing: "/Tenant/GetPublicTenantList",
    details: "/Tenant/GetPublicTenantById",
    branches: "/Branch/GetTenantBranches",
    workingSystems: "/Appointment/GetBranchAvailableDays",
    workingSlots: "/Appointment/GetBranchAvailableDaySlots",
  },
  reservations: {
    add: "/Reservation/AddReservation",
    listing: "/UserProfile/GetUserReservations",
    details: "/UserProfile/GetUserReservationById",
    DetailsByQR:"/Reservation/GetReservationDetailsByQR"
  },
  auth: {
    login: "/Auth/Login",
    register: "/Auth/Register",
    refreshToken:"/Auth/RefreshToken"
  },
  userProfile: {
    get: "/UserProfile/GetUserProfileData",
    update: "/UserProfile/UpdateUserProfileData",
    changePassword: "/UserProfile/ChangePassword",
  },
  survey: {
    get: "/Survey/GetUserSurvey",
    submit: "/Survey/SubmitUserSurvey",
  },
  newsletter: {
    add: "/Newsletter/AddNewsletter",
  },
  contactUs: {
    add: "/ContactRequest/AddContactRequest",
  },
};
