import { ChangePasswordScreen } from "../screens/auth/changePasswordScreen";
import ForgetPassword from "../screens/auth/forgetPassword";
import { LoginScreen } from "../screens/auth/loginScreen";
import { RegisterScreen } from "../screens/auth/registerScreen";
import { BookingDetailsScreen } from "../screens/booking/bookingDetails";
import { ContactUsScreen } from "../screens/contactUs/contactUsScreen";
import { HomeScreen } from "../screens/home";
import { HowItWorksScreen } from "../screens/howItWorks/howItWorksScreen";
import PaymentsScreen from "../screens/myPayments";
import { MyReservationsScreen } from "../screens/myReservations";
import PointsScreen from "../screens/mypoints";
import ReviewsScreen from "../screens/reviews";
import { SurveyScreen } from "../screens/survey/surveyScreen";
import { UserProfileScreen } from "../screens/userProfile";
import { TabLayout } from "../screens/userTab/tabLayout";
import ReservationsDetailsScreen from './../screens/ReservationsDetails/ReservationsDetailsScreen';

export const appRoutes = [
  {
    id: "home",
    name: "home",
    component: <HomeScreen />,
    link: "/home",
    permission: undefined,
  },
  {
    id: "bookingDetails",
    name: "bookingDetails",
    component: <BookingDetailsScreen />,
    link: "/booking/bookingDetails/:id",
    permission: undefined,
  },

  {
    id: "login",
    name: "login",
    component: <LoginScreen />,
    link: "/login",
    permission: undefined,
  },
  {
    id: "register",
    name: "register",
    component: <RegisterScreen />,
    link: "/register",
    permission: undefined,
  },
  {
    id: "howItWorks",
    name: "howItWorks",
    component: <HowItWorksScreen />,
    link: "/howItWorks",
    permission: undefined,
  },
  {
    id: "myProfile",
    name: "myProfile",
    component: <TabLayout>{<UserProfileScreen />}</TabLayout>,
    link: "/myProfile",
    permission: undefined,
  },
  {
    id: "contactUs",
    name: "contactUs",
    component: <ContactUsScreen />,
    link: "/contactUs",
    permission: undefined,
  },
  {
    id: "myReservations",
    name: "myReservations",
    component: <TabLayout>{<MyReservationsScreen />}</TabLayout>,
    link: "/myReservations",
    permission: undefined,
  },

  {
    id: "survey",
    name: "survey",
    component: <SurveyScreen />,
    link: "/survey/:code",
    permission: undefined,
  },
  {
    id: "changePassword",
    name: "changePassword",
    component: <TabLayout>{<ChangePasswordScreen />}</TabLayout>,
    link: "/changePassword",
    permission: undefined,
  },
  {
    id: "myPoints",
    name: "myPoints",
    component: <TabLayout>{<PointsScreen />}</TabLayout>,
    link: "/myPoints",
    permission: undefined,
  },
  {
    id: "myPayments",
    name: "myPayments",
    component: <TabLayout>{<PaymentsScreen />}</TabLayout>,
    link: "/myPayments",
    permission: undefined,
  },
  {
    id: "myReviews",
    name: "myReviews",
    component: <TabLayout>{<ReviewsScreen />}</TabLayout>,
    link: "/myReviews",
    permission: undefined,
  },
  {
    id: "forgetPassword",
    name: "forgetPassword",
    component: <ForgetPassword />,
    link: "/forgetPassword",
    permission: undefined,
  },
  {
    id: "ReservationsDetails",
    name: "ReservationsDetails",
    component: <ReservationsDetailsScreen />,
    link: "/myReservations/ReservationsDetails/:id",
    permission: undefined,
  }
];
