import { useTranslation } from "react-i18next"

export const AboutSection = () => {
    const { t } = useTranslation()
    return <section className="row_am about_app_section">
        <div className="container">
            <div className="row">
                <div className="col-lg-6">

                    <div className="about_img" data-aos="fade-in" data-aos-duration="1500">
                        <div className="banner_image">
                            <img className="moving_animation" src="/images/features-02.png" alt="image" />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6">

                    <div className="about_text">
                        <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">

                            <h2>{t("How can")} <span>Tableers</span> {t("help your business?")}</h2>

                            <p>
                                {t("about_quote")}
                            </p>
                        </div>

                        <ul className="app_statstic" id="counter" data-aos="fade-in" data-aos-duration="1500">
                            <li>
                                <div className="icon">
                                    <img src="/images/why-icon-1.png" alt="image" />
                                </div>
                                <div className="text">
                                    <p><span className="counter-value" data-count="15">0</span><span>min</span></p>
                                    <p>{t("Time saved per table")}</p>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <img src="/images/why-icon-2.png" alt="image" />
                                </div>
                                <div className="text">
                                    <p><span className="counter-value" data-count="300">0 </span><span>%</span></p>
                                    <p>{t("More Tips")}</p>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <img src="/images/why-icon-3.png" alt="image" />
                                </div>
                                <div className="text">
                                    <p><span className="counter-value" data-count="7">1500</span><span>x</span></p>
                                    <p>{t("Positive Reviews")}</p>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <img src="/images/why-icon-4.png" alt="image" />
                                </div>
                                <div className="text">
                                    <p><span className="counter-value" data-count="100">0</span><span>%</span></p>
                                    <p>{t("Customer Satisfaction")}</p>
                                </div>
                            </li>
                        </ul>
                        <a href="/contactUs" className="btn puprple_btn" data-aos="fade-in" data-aos-duration="1500">{t("book_now")}</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
}