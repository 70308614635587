import { Route, Routes, useLocation, useNavigate } from "react-router";
import { appRoutes } from "./routes";
import { NoMatch } from "./noMatch";
import PublicRoute from "./publicRoute";
import { useDispatch } from "react-redux";
import { HomeScreen } from "../screens/home";
import "../apis/axios/axiosInterceptors";
import { getLanguages } from "../redux/actions/languageActions";
import { useEffect } from "react";
const AppRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLanguages());
  }, []);
  return (
    <Routes>
      <Route
        exact={true}
        path={"/"}
        element={<PublicRoute>{<HomeScreen />}</PublicRoute>}
      ></Route>
      {appRoutes.map((item, index) => {
        return (
          <Route
            key={index}
            exact={true}
            path={item.link}
            element={<PublicRoute>{item.component}</PublicRoute>}
          ></Route>
        );
      })}
      <Route path={"*"} element={<NoMatch />}></Route>
    </Routes>
  );
};

export default AppRoutes;
