import AppRoutes from "./AppRoutes";
import { CustomRouter } from "./customRouter";
import history from "./historyObj";

const AppRouting = () => {
  return (
    <CustomRouter history={history}>
      <AppRoutes />
    </CustomRouter>
  );
};

export default AppRouting;
