const initState = {
  language: "en",
};

export const LanguageReducer = (state = initState, action) => {
  switch (action.type) {
    case "GET_LANG":
      return { ...state, getLang: action.payload };
    case "CHANGE_LANG":
      return { ...state, language: action.payload };

    default:
      return state;
  }
};
