import { useTranslation } from "react-i18next"

export const HowItWorksScreen = () => {
    const { t } = useTranslation()
    return <>
        <div className="bred_crumb">
            <div className="container">
                <span className="banner_shape1"> <img src="/images/banner-shape1.png" alt="image" /> </span>
                <span className="banner_shape2"> <img src="/images/banner-shape2.png" alt="image" /> </span>
                <span className="banner_shape3"> <img src="/images/banner-shape3.png" alt="image" /> </span>

                <div className="bred_text">
                    <h1>{t("howItWorks")}</h1>
                    <ul>
                        <li><a href="/home">{t("home")}</a></li>
                        <li><span>»</span></li>
                        <li><a href="#">{t("howItWorks")}</a></li>
                    </ul>
                </div>
            </div>
        </div>


        <section className="row_am how_it_works" id="how_it_work">
            <div className="container">
                <div className="how_it_inner">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                        <h2><span>{t("table_reservation")}</span>{t("3-easy-steps")}</h2>
                        <p>{t("Allow customers to book a table through all your online channels,")}<br />{t("tableer will check availability in real time and assign a table automatically.")}</p>
                    </div>
                    <div className="step_block">
                        <ul>
                            <li>
                                <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                    <h4>{t("Online Channels")}</h4>
                                    <p>{t("onlineChannelsQuote")}</p>
                                </div>
                                <div className="step_number">
                                    <h3>01</h3>
                                </div>
                                <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                                    <img src="/images/how-1-1.png" alt="image" />
                                </div>
                            </li>

                            <li>
                                <div className="step_text" data-aos="fade-left" data-aos-duration="1500">
                                    <h4>{t("Reservation Details")}</h4>
                                    <p>{t("reservationDetailsQuote")}</p>
                                </div>
                                <div className="step_number">
                                    <h3>02</h3>
                                </div>
                                <div className="step_img" data-aos="fade-right" data-aos-duration="1500">
                                    <img src="/images/how-1-2.png" alt="image" />
                                </div>
                            </li>

                            <li>
                                <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                    <h4>{t("Account")}</h4>
                                    <p>{t("accountQuote")}</p>
                                </div>
                                <div className="step_number">
                                    <h3>03</h3>
                                </div>
                                <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                                    <img src="/images/how-1-3.png" alt="image" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>


            </div>
        </section>

        <section className="row_am how_it_works" id="how_it_work">
            <div className="container">
                <div className="how_it_inner">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                        <h2><span>{t("payment")}</span>{t("3-easy-steps")}</h2>
                        <p>{t("By scanning a QR code on the table, the customer can pay the bill easily")}<br />{t("using his mobile phone. Plus some features like bill splitting and Tips.")}</p>
                    </div>
                    <div className="step_block">
                        <ul>
                            <li>
                                <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                    <h4>{t("QR code")}</h4>
                                    <p>{t("Scanning a QR code on the table")}</p>
                                </div>
                                <div className="step_number">
                                    <h3>01</h3>
                                </div>
                                <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                                    <img src="/images/how-2-1.png" alt="image" />
                                </div>
                            </li>

                            <li>
                                <div className="step_text" data-aos="fade-left" data-aos-duration="1500">
                                    <h4>{t("Bill Payment")}</h4>
                                    <p>{t("The user will pay the bill using one of the available payment options.")}</p>
                                </div>
                                <div className="step_number">
                                    <h3>02</h3>
                                </div>
                                <div className="step_img" data-aos="fade-right" data-aos-duration="1500">
                                    <img src="/images/how-2-2.png" alt="image" />
                                </div>
                            </li>

                            <li>
                                <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                    <h4>{t("Successful Message")}</h4>
                                    <p>{t("A message will appear confirming that the payment was recieveed successfully")}</p>
                                </div>
                                <div className="step_number">
                                    <h3>03</h3>
                                </div>
                                <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                                    <img src="/images/how-2-3.png" alt="image" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>


            </div>
        </section>



        <section className="row_am how_it_works" id="how_it_work">
            <div className="container">
                <div className="how_it_inner">
                    <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                        <h2><span>{t("Customers Review")}</span>{t("3-easy-steps")}</h2>
                        <p>{t("Customers will be asked to write a review and rate their experience.")}</p>
                    </div>
                    <div className="step_block">
                        <ul>
                            <li>
                                <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                    <h4>{t("QR code")}</h4>
                                    <p>{t("After scanning the QR code and completing the payment, the user will be redirected to the review section")}</p>
                                </div>
                                <div className="step_number">
                                    <h3>01</h3>
                                </div>
                                <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                                    <img src="/images/how-2-1.png" alt="image" />
                                </div>
                            </li>

                            <li>
                                <div className="step_text" data-aos="fade-left" data-aos-duration="1500">
                                    <h4>{t("Review")}</h4>
                                    <p>{t("Customers will be asked to write a review and rate their experience.")}</p>
                                </div>
                                <div className="step_number">
                                    <h3>02</h3>
                                </div>
                                <div className="step_img" data-aos="fade-right" data-aos-duration="1500">
                                    <img src="/images/how-3-2.png" alt="image" />
                                </div>
                            </li>

                            <li>
                                <div className="step_text" data-aos="fade-right" data-aos-duration="1500">
                                    <h4>{t("Successful Message")}</h4>
                                    <p>{t("A message will appear confirming that the review was submitted successfully")}</p>
                                </div>
                                <div className="step_number">
                                    <h3>03</h3>
                                </div>
                                <div className="step_img" data-aos="fade-left" data-aos-duration="1500">
                                    <img src="images/how-3-3.png" alt="image" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>


            </div>
        </section>





    </>
}