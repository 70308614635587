export const CrossedEyeIcon = ({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      strokeWidth={1}
      stroke="gray"
      strokeLinecap="square"
      ariaLabelledby="eyeCrossedIconTitle"
      color="#000"
      viewBox="0 0 24 24"
    >
      <path d="M22 12s-3 6-10 6-10-6-10-6 3-6 10-6 10 6 10 6z"></path>
      <circle cx="12" cy="12" r="3"></circle>
      <path d="M3 21L20 5"></path>
    </svg>
  );
};
