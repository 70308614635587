import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router";
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../../helpers/formik";
import { AppCheckBox } from "../../components/formComponents/checkbox";
import { loginAPI } from "../../apis/auth";
import Cookies from "universal-cookie";
import moment from "moment";
import { CrossedEyeIcon } from "../../styles/crossedEyeIcon";
import { EyeIcon } from "../../styles/eyeIcon";
import { getLang } from "../../helpers/localizationHelper";
import { Link, useSearchParams } from "react-router-dom";
import { LanguageMenu } from "../../components/header/languageMenu";

export const LoginScreen = () => {
    const cookies = new Cookies()
    const [searchParams, setSearchParams] = useSearchParams();
    const returnUrl = searchParams?.get('returnUrl')
    const { t } = useTranslation()
    const [showPassword, setShowPassword] = useState(true)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const submit = async (values: any) => {
        setLoading(true)
        try {
            const res = await (loginAPI({ ...values }))
            if (res?.status) {
                cookies.set("token", `Bearer ${res?.token}`, { expires: moment(res?.tokenTime).toDate(), path: "/" })
                cookies.set("refreshToken", `${res?.refreshToken}`,{expires:moment(res?.refreshTokenTime).toDate(),path:"/"})                 
              
                if (returnUrl != null) {
                    navigate(`${returnUrl}`)
                } else navigate('/home')
            }
        } catch (e: any) {
            //setBackEndErrors(e?.response?.data?.errors, setFieldError)
        }
        setLoading(false)
    };
    const formik = useFormik(
        formikSetup(initialValues, undefined, validationSchema, 'login', submit),
    );
    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError } = formik;
    const isValid = formikFieldValidation(touched, errors);
    // useEffect(() => {
    //     setRememberMe(localStorage.getItem('remember') == "true" ? true : false)
    //     //@ts-ignore
    //     localStorage.setItem('remember', rememberMe)
    // }, [])
    return <div className="full_bg">

        <div className="container">
            <section className="signup_section">
                <div className="d-flex w-100 justify-content-between my-4 align-items-baseline">
                  
                        <a dir={getLang() == "ar-SA" ? "rtl" : "ltr"}
                            style={{ cursor: 'pointer' }} onClick={() => {
                                navigate(-1)
                            }} className="back_btn">
                            {getLang() == "ar-SA" ? <i className="icofont-arrow-right"></i> : <i className="icofont-arrow-left"></i>} 
                            {t("back")}
                        </a>
                        <a className="navbar-brand m-0" href="#">
                            <img src="/images/footer_logo.png" alt="image" />
                        </a>
                    <div dir={getLang() == "ar-SA" ? "rtl" : "ltr"} className='abs-container'>
                        <LanguageMenu />
                    </div>
                </div>
                <div className="signup_form">
                    <div className="section_title">
                        <h2> <span>{t('Login to')}</span>{t("your account")}</h2>
                        <p>{t("Fill all fields so we can get some info about you.")} <br /> {t("We'll never send you spam")}</p>

                    </div>
                    <form className="mt-4">
                        <div className="form-group">
                            <input value={values?.email} onChange={handleChange("email")} className="form-control" placeholder={t("email")} />
                            <p style={{ color: 'red', textAlign: 'start' }}>{isValid('email')}</p>
                        </div>
                        <div className="form-group" style={{ position: 'relative' }}>
                            <input value={values?.password} onChange={handleChange("password")} type={!showPassword ? "text" : "password"} className="form-control" placeholder={t("password")} />
                            <div onClick={() => { setShowPassword(prev => !prev) }} style={{ position: 'absolute', top: 16, right: getLang() == "ar-SA" ? undefined : 10, left: getLang() == "ar-SA" ? 10 : undefined }}>
                                {!showPassword ? <CrossedEyeIcon color="gray" /> : <EyeIcon />
                                }        </div>
                            <p style={{ color: 'red', textAlign: 'start' }}>{isValid('password')}</p>
                        </div>
                        <div className="form-group d-flex justify-content-end">
                          
                            <a href="/forgetPassword" style={{color: 'rgb(106, 73, 242)'}}>
                                {t('forgetPassword?')}
                            </a>
                        </div>
                        <div className="form-group">
                            <button className="btn puprple_btn" onClick={(e) => {
                                e.preventDefault()
                                handleSubmit?.()
                            }}>{t("signIn")}</button>
                        </div>
                    </form>
                    <p className="or_block">
                        <span>{t("OR")}</span>
                    </p>
                    <div className="or_option">
                        <p>{t("notHaveAccount")}
                            <a style={{ textDecoration: 'none' }}
                                onClick={async () => navigate('/register' + `?returnUrl=${returnUrl}`)}>
                                {t("signUpHere")}

                            </a>
                        </p>
                    </div>
                </div>
            </section>
        </div>

    </div>
}