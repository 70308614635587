import React, { useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import { getLang } from '../helpers/localizationHelper'

export function RatingComponent({ setRate, tooltipArray, tooltipText }: { tooltipArray?: any, tooltipText?: any, setRate: any }) {

    // Catch Rating value
    const handleRating = (rate: number) => {
        setRate(rate)

        // other logic
    }

    return (

        <Rating
            size={30}
            rtl={getLang() == "ar-SA" ? true : false}
            tooltipStyle={{ fontSize: 10 }}
            tooltipDefaultText={tooltipText}
            tooltipArray={tooltipArray}
            showTooltip
            onClick={handleRating}

        /* Available Props */
        />

    )
}