import { useEffect, useState } from "react"
import { resolveRequest, toBase64 } from "../../helpers"
import { updatePassword, updateUserProfile, userProfileData } from "../../apis/userProfile"
import { useTranslation } from "react-i18next"
import { useFormik } from "formik"
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../../helpers/formik"
import { AppDatepicker } from "../../components/formComponents/datePicker"
import RadioButtonControl from "../../components/formComponents/radioButtons"
import { getLang } from "../../helpers/localizationHelper"
import { Loader } from "../../components/loader"
import PlusIcon from "../../styles/plusIcon"
import RemoveIcon from "../../styles/removeIcon"
import { GetAllCountries } from "../../apis/lookups"
import { baseURLPhotos } from "../../apis/endpoints"

export const UserProfileScreen = () => {
    const { t } = useTranslation()
    const [country, setCountry] = useState<any>()
    const [isPasswod,] = useState(false)
    const [userProfile, setUserProfile] = useState<any>()
    const [rerequest, setrerequest] = useState(false)
    const [logoFile, setLogoFile] = useState<any>()
    const [isLoading, setLoading] = useState(false)
    const [previewLogo, setPreviewLogo] = useState<any>()
    useEffect(() => {

        resolveRequest(userProfileData, setUserProfile)
    }, [rerequest])
    const submit = async (values: any) => {
        try {
            setLoading(true)
            const res = await updateUserProfile({ data: { ...values, id: userProfile?.id, profileImage: logoFile ?? "", isProfileImageChanged: logoFile || values?.profileImage == null ? true : false, dialCode: country } })
            if (res?.status) {

                setrerequest(prev => !prev)
            }
            setLoading(false)

        } catch (e: any) {
            setLoading(false)

            //setBackEndErrors(e?.response?.data?.errors, setFieldError)
        }
    };
    const formik = useFormik(
        formikSetup(initialValues, userProfile, validationSchema, 'myProfile', submit),
    );
    const [allCountries, setAllCountries] = useState<any>()
    useEffect(() => {
        resolveRequest(GetAllCountries, setAllCountries)

    }, [getLang()])
    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError } = formik;
    useEffect(() => {
        if (userProfile)
            setCountry(userProfile?.dialCode)
    }, [userProfile])
    const isValid = formikFieldValidation(touched, errors);
    const submit2 = async (values: any) => {
        try {
            setLoading(true)
            const res = await updatePassword({ data: values })
            if (res?.status) {
                resetForm({ values: {} })
            }
            setLoading(false)

        } catch (e: any) {
            setLoading(false)

            //setBackEndErrors(e?.response?.data?.errors, setFieldError)
        }
    };
    const formik2 = useFormik(
        formikSetup(initialValues, undefined, validationSchema, 'changePassword', submit2),
    );
    const { handleChange: handleChange2, handleSubmit: handleSubmit2, values: values2, touched: touched2, errors: errors2, resetForm } = formik2;
    const isValid2 = formikFieldValidation(touched2, errors2);
    return <>
 
        <div className="profile-con mb-4">
            <div className="row">
                <div className="col-md-3 border-right">
                    <input id="file" accept=".jpg, .png ,.jpeg" onChange={async (event: any) => {
                        setLogoFile(await toBase64(event.target.files?.[0]))
                        const objectUrl = URL.createObjectURL(event.target.files?.[0])
                        setPreviewLogo(objectUrl)
                        setFieldValue('profileImage', null)

                    }} style={{ display: 'none' }} className="form-control mt-3" type="file" />

                    <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                        <div style={{ position: 'relative', marginBottom: 20 }}>
                            {<img style={{
                                width: 200,
                                height: 200,
                                borderRadius: "50%",
                                objectFit: "cover",

                            }} src={values?.profileImage ? (baseURLPhotos + "/" + values?.profileImage) : previewLogo ? previewLogo : values?.gender == false ? '/man.jpg' : '/woman.jpg'} />}
                            <div style={{
                                position: 'absolute', bottom: -10, right: '31%',
                                textAlign: 'center',
                                width: 80,
                                backgroundColor: 'white',
                                borderWidth: .5, borderColor: 'black', borderStyle: 'solid', borderRadius: 20,
                                display: 'flex',
                                justifyContent: 'center'


                            }}>
                                <div style={{ marginInlineEnd: 10 }} onClick={() => {
                                    if (logoFile || values?.profileImage) {
                                        setPreviewLogo(null)
                                        setLogoFile(null)
                                        setFieldValue('profileImage', null)
                                        if (document.getElementById("file")) {
                                            //@ts-ignore
                                            document.getElementById("file").value = ""
                                        }
                                    }


                                }}>
                                    <RemoveIcon color={(logoFile || values?.profileImage) ? "url(#wRKXFJsqHCxLE9yyOYHkza)" : "lightGray"} />
                                </div>
                                <label
                                    htmlFor="file"
                                    className="plus"
                                    style={{ padding: 0, margin: 0 }}
                                >
                                    <PlusIcon /></label>

                            </div>
                        </div>
                        <span className="font-weight-bold">{userProfile?.fullName}</span><span className="text-black-50">{userProfile?.email}</span><span> </span></div>

                </div>
                <div className="col-md-9">
                    <div className="p-3 py-5">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h4 className="text-right">{t("Profile Settings")}</h4>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-6">
                                <label className="labels">
                                    {t("firstName")}
                                    <span className="text-danger  mx-1">*</span>
                                </label>
                                <input onChange={handleChange("firstName")} type="text" className="form-control"
                                    style={{
                                        border: isValid('firstName') ? '1px solid red' : '',
                                        borderRadius: "8px",
                                        padding: "19px"
                                    }}
                                    value={values?.firstName} />
                            </div>
                            <div className="col-md-6">
                                <label className="labels">
                                    {t("lastName")}
                                    <span className="text-danger  mx-1">*</span>
                                </label>
                                <input style={{
                                    border: isValid('lastName') ? '1px solid red' : '',
                                    borderRadius: "8px",
                                    padding: "19px"
                                }}
                                    onChange={handleChange("lastName")} type="text"
                                    className="form-control" value={values?.lastName} />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6">
                                <div style={{ flexDirection: 'column', display: 'flex' }}>
                                    <label className="labels">
                                        {t("mobile")}
                                        <span className="text-danger  mx-1">*</span>
                                    </label>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <select style={{ width: '35%', marginInlineEnd: 10 }} defaultValue={undefined} value={country} onChange={(e) => {
                                        setCountry(e.target.value)
                                    }} className="form-control">{/* default-select  */}
                                        <option disabled selected value="">{t("country")}</option>
                                        {allCountries && allCountries?.map((item: any) => {
                                            return <option key={item?.dialCode} value={item?.dialCode}>{item.fullCode}</option>
                                        })

                                        }
                                    </select>
                                    <input style={{
                                        border: isValid("mobile") ? '1px solid red' : '', width: '65%', borderRadius: "8px",
                                        padding: "19px"
                                    }} onChange={handleChange("mobile")} type="text" className="form-control" value={values?.mobile} />
                                </div>
                                {/* {isValid("mobile") && <p style={{ color: 'red', fontSize: 10, textAlign: 'start' }}>{isValid("mobile")}</p>} */}
                            </div>
                            <div className="col-md-6">
                                <label className="labels">
                                    {t("email")}
                                    <span className="text-danger  mx-1">*</span>
                                </label>
                                <input disabled type="text" className="form-control"
                                    value={values?.email}
                                    style={{
                                        border: isValid('email') ? '1px solid red' : '',
                                        borderRadius: "8px",
                                        padding: "19px"
                                    }} />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-6"><label className="labels">{t("dateOfBirth")}</label>
                                <AppDatepicker value={values?.dateOfBirth}
                                    onChange={(e) => {
                                        setFieldValue("dateOfBirth", e)
                                    }} /></div>
                            <div className="col-md-6">
                                <p style={{ textAlign: "start", marginBottom: 0 }}>{t("gender")}</p>
                                <RadioButtonControl
                                    buttons={[{
                                        label: t("female"), onChange: () => {
                                            setFieldValue("gender", true)
                                        }, checked: values?.gender
                                    }, {
                                        label: t("male"),
                                        onChange: () => {
                                            setFieldValue("gender", false)
                                        },
                                        checked: !values?.gender
                                    }]}

                                />

                            </div>
                        </div>



                        {isLoading == false ? <div style={{ zIndex: -1 }} className="mt-5 text-center"><button className="btn puprple_btn aos-init aos-animate" type="button"
                            //@ts-ignore
                            onClick={handleSubmit}>{t("save")}</button>

                        </div> : <Loader />}
                    </div>
                </div>
            </div>
        </div>

    </>
}