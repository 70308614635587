import axiosCall from "../axios/axiosConfig";
import { endpoints } from "../endpoints";

export const getSurveyCode = ({ code }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.survey.get, { code: code })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({ status: true, data: res?.data?.data || res?.data?.obj });
        } else if (res?.response?.data?.code == 400) {
          resolve({
            status: true,
            data: res?.response?.data?.message,
          });
        } else {
          resolve({
            status: false,
            data: res?.response?.data?.data || res?.response?.data?.obj,
          });
        }
      })
      .catch((e) => {
        console.log("error", e);
        resolve({ status: false });
      });
  });
};
export const submitSurvey = ({ data }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.survey.submit, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else {
          console.log("error reject", res);

          reject(res);
        }
      })
      .catch((e) => {
        console.log("error res", e);
        reject(e);
      });
  });
};
