import { useTranslation } from "react-i18next";
import { addNewsTeller } from "../../apis/staticPages";
import { useFormik } from "formik";
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../../helpers/formik";

export const Footer = () => {
    const { t } = useTranslation()
    const submit2 = async (values: any) => {
        try {
            const res = await addNewsTeller({ data: { ...values, id: null } })
            if (res?.status) {
                resetForm({ values: {} })
            }

        } catch (e: any) {

            //setBackEndErrors(e?.response?.data?.errors, setFieldError)
        }
    };
    const formik2 = useFormik(
        formikSetup(initialValues, undefined, validationSchema, 'newsTeller', submit2),
    );
    const { handleChange: handleChange2, handleSubmit: handleSubmit2, values: values2, touched: touched2, errors: errors2, resetForm } = formik2;
    const isValid2 = formikFieldValidation(touched2, errors2);

    return <>
        <section className="newsletter_section">
            <div className="container">
                <div className="newsletter_box">
                    <div className="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                        <h2>{t("Subscribe newsletter")}</h2>
                        <p>{t("Be the first to recieve all latest post in your inbox")}</p>
                    </div>
                    <form action="" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                        <div className="form-group">
                            <input style={{ borderColor: isValid2('email') ? 'red' : undefined }} value={values2?.email} onChange={handleChange2("email")} type="email" className="form-control" placeholder={t("Enter your email")} />
                        </div>
                        <div className="form-group">
                            <button onClick={(e) => {
                                e.preventDefault()
                                handleSubmit2?.()
                            }} className="btn">{t("SUBMIT")}</button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
        <footer>
            <div className="top_footer" id="contact">
                <div className="container">
                    <div className="row">
                        <span className="banner_shape1"> <img src="images/banner-shape1.png" alt="image" /> </span>
                        <span className="banner_shape2"> <img src="images/banner-shape2.png" alt="image" /> </span>
                        <span className="banner_shape3"> <img src="images/banner-shape3.png" alt="image" /> </span>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="abt_side">
                                <div className="logo"> <img src="/images/logo.png" alt="image" /></div>


                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="links">
                                <ul>
                                    <li className="nav-item"><a className="nav-link" href="/home#features">{t("Features")}</a></li>
                                    <li className="nav-item"><a className="nav-link" href="/howItWorks">{t("howItWorks")}</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="links">
                                <ul>
                                    <li className="nav-item"><a className="nav-link" href="/home#pricing">{t("pricing")}</a> </li>
                                    <li className="nav-item"><a className="nav-link" href="/contactUs">{t("contactUs")}</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="abt_side">


                                <ul className="social_media">
                                    <li><a href="#"><i className="icofont-facebook"></i></a></li>
                                    <li><a href="#"><i className="icofont-twitter"></i></a></li>
                                    <li><a href="#"><i className="icofont-instagram"></i></a></li>
                                    <li><a href="#"><i className="icofont-pinterest"></i></a></li>
                                </ul>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="bottom_footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p>{t("copyRights")}</p>
                        </div>

                    </div>
                </div>
            </div>

            <div onClick={() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth' // for smoothly scrolling
                });
            }} className="go_top">
                <span><img src="images/go_top.png" alt="image" /></span>
            </div>
        </footer>



        <div className="purple_backdrop"></div>

    </>
}