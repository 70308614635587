import { useSelector } from "react-redux"
import { changeLang, getLang } from "../../helpers/localizationHelper"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"

export const LanguageMenu = () => {
    const lang = useSelector((state: any) => state?.language?.getLang)
    const { t } = useTranslation()
    return <>
        <Helmet>
            <link rel='stylesheet' href='/css/menu.css' />
        </Helmet>
        <li className="nav-item has_dropdown">
            <a className="nav-link"><div className="profile-icon none-mobile"><img src={getLang() == "ar-SA" ? "/images/ar-lang.png" : "/images/en-lang.png"} alt="image" className="menu-flag-select" /></div>
            {/* <span className="only-mobile">{t('language')}</span> */}
            </a>
            <span dir={getLang() == "ar-SA" ? "rtl" : "ltr"} className="drp_btn"><i className="icofont-rounded-down"></i></span>
            <div className="sub_menu language_menu" dir={getLang() == "ar-SA" ? "rtl" : "ltr"}>
                <ul>
                    {lang && lang?.map((item: any, index: any) => {
                        return <li key={index}><a onClick={() => {
                            localStorage.setItem("lang", item?.code)
                            setTimeout(() => {
                                window.location.reload()
                            }, 500);
                        }}><img src={item?.code == 'en-US' ? "/images/en-lang.png" : "/images/ar-lang.png"} alt="" className="menu-flag" />{item?.title}</a></li>
                    })}
                </ul>
            </div>
        </li>
    </>
}