import { getLang } from "../../helpers/localizationHelper";
import axiosCall from "../axios/axiosConfig";
import { endpoints } from "../endpoints";

export const listingRestaurant = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.restaurants.listing)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const listingBranches = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.restaurants.branches, { id: id })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else resolve([]);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const workingSystems = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.restaurants.workingSystems, { id: id })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else resolve([]);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const workingSlots = ({ id, selectedDate,partySize }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.restaurants.workingSlots, {
        branchId: id,
        partySize: partySize,
        selectedDate: selectedDate,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj || []);
        } else resolve([]);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const detailsRestaurant = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.restaurants.details, { id: id })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({ data: res?.data?.data || res?.data?.obj, status: true });
        } else reject({ message: res?.data?.message, status: false });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const addReservation = ({
  appointmentSlotId,
  branchId,
  partySize,
  // dateTimeSlot,
  isWaiting = false,
}) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.reservations.add, {
        appointmentSlotId,
        partySize,
        specialRequest: "",
        isWaiting,
        branchId,
        // dateTimeSlot,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else {
          console.log("error reject", res);

          reject(res);
        }
      })
      .catch((e) => {
        console.log("error res", e);
        reject(e);
      });
  });
};


export const GetReservationDetailsByQR = ({id}) => {
  return new Promise((resolve,reject)=>{
    axiosCall.post(endpoints.reservations.DetailsByQR,{
      id:id
    }).then((res)=>{
      if(res?.data?.code == 200){
        resolve({
          data:res.data.data || res.data.obj,
          status:true
        })
      }else reject({ message: res?.data?.message, status: false });
    }).catch((e)=>{
      reject(e)
    })
  });
}
