import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getLang } from "../../helpers/localizationHelper"
import { resolveRequest } from "../../helpers"
import { GetAllCountries } from "../../apis/lookups"
import { useFormik } from "formik"
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../../helpers/formik"
import { addContactus } from "../../apis/staticPages"

export const ContactUsScreen = () => {
    const { t } = useTranslation()
    const [country, setCountry] = useState<any>()
    const [isLoading, setLoading] = useState(false)
    const [allCountries, setAllCountries] = useState<any>()

    useEffect(() => {
        resolveRequest(GetAllCountries, setAllCountries)

    }, [getLang()])
    const submit = async (values: any) => {
        try {
            setLoading(true)
            const res = await addContactus({ data: { ...values, dialCode: country } })
            if (res?.status) {
                setCountry(undefined)
                resetForm({ values: {} })
            }
            setLoading(false)

        } catch (e: any) {
            setLoading(false)

            //setBackEndErrors(e?.response?.data?.errors, setFieldError)
        }
    };
    const formik = useFormik(
        formikSetup(initialValues, undefined, validationSchema, 'contactUs', submit,),

    );
    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError, resetForm
    } = formik;
    const isValid = formikFieldValidation(touched, errors);

    return <>
        <div className="bred_crumb">
            <div className="container">
                <span className="banner_shape1"> <img src="/images/banner-shape1.png" alt="image" /> </span>
                <span className="banner_shape2"> <img src="/images/banner-shape2.png" alt="image" /> </span>
                <span className="banner_shape3"> <img src="/images/banner-shape3.png" alt="image" /> </span>

                <div className="bred_text">
                    <h1>{t("contact us")}</h1>
                    <p>{t("If you have an query, please get in touch with us, we will revert back quickly.")}</p>
                    <ul>
                        <li><a href="/home">{t("home")}</a></li>
                        <li><span>»</span></li>
                        <li><a href="#">{t("contact us")}</a></li>
                    </ul>
                </div>
            </div>
        </div>


        <section className="contact_page_section">
            <div className="container">
                <div className="contact_inner">
                    <div className="contact_form">
                        <div className="section_title">
                            <h2>{t("Leave a")} <span>{t("message")}</span></h2>
                            <p>{t("Fill up form below, our team will get back soon")}</p>
                        </div>
                        <form action="">
                            <div className="form-group">
                                <label className="labels">{t("Name")}</label>

                                <input style={{ borderColor: isValid('name') ? 'red' : undefined }} onChange={handleChange("name")} value={values?.name} type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="labels">{t("Email")}</label>

                                <input style={{ borderColor: isValid('email') ? 'red' : undefined }} onChange={handleChange("email")} value={values?.email} type="email" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="labels">{t("Company Name")}</label>

                                <input style={{ borderColor: isValid('company') ? 'red' : undefined }} onChange={handleChange("company")} value={values?.company} type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <div style={{ flexDirection: 'column', display: 'flex' }}>
                                    <label className="labels">{t("mobile")}</label>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <select style={{ borderColor: isValid('mobile') ? 'red' : undefined, width: '35%', marginInlineEnd: 10 }} defaultValue={undefined} value={country} onChange={(e) => {
                                        setCountry(e.target.value)
                                    }} className="form-control">{/* default-select  */}
                                        <option disabled selected value="">{t("Country")}</option>
                                        {allCountries && allCountries?.map((item: any) => {
                                            return <option key={item?.dialCode} value={item?.dialCode}>{item.fullCode}</option>
                                        })

                                        }
                                    </select>
                                    <input disabled={country === undefined} style={{ width: "65%", borderColor: isValid('mobile') ? 'red' : undefined }} onChange={handleChange("mobile")} type="text" className="form-control" value={values?.mobile} />
                                </div>

                            </div>
                            <div className="form-group">
                                <label className="labels">{t("Website")}</label>

                                <input style={{ borderColor: isValid('website') ? 'red' : undefined }} onChange={handleChange("website")} value={values?.website} type="text" className="form-control" />
                            </div>
                            <div className="form-group">
                                <label className="labels">{t("Your message")}</label>

                                <textarea style={{ borderColor: isValid('message') ? 'red' : undefined }} onChange={handleChange("message")} value={values?.message} className="form-control"></textarea>
                            </div>
                            {/*     <div className="form-group term_check">
                                <input type="checkbox" id="term" />
                                <label htmlFor="term">{t("I agree to receive emails, newsletters and promotional messages")}</label>
                            </div> */}
                            <div className="form-group mb-0">
                                <button onClick={(e) => {
                                    e.preventDefault()
                                    handleSubmit?.()
                                }} className="btn puprple_btn">{t("SEND MESSAGE")}</button>
                            </div>
                        </form>
                    </div>
                    <div className="contact_info">
                        <div className="icon"><img src="/images/contact_message_icon.png" alt="image" /></div>
                        <div className="section_title">
                            <h2>{t("Have any")} <span>{t("question?")}</span></h2>
                            <p>{t("If you have any question, feel free to contact us")}</p>
                        </div>

                        <ul className="contact_info_list">
                            <li>
                                <div className="img">
                                    <img src="/images/mail_icon.png" alt="image" />
                                </div>
                                <div className="text">
                                    <span>{t("Email Us")}</span>
                                    <a href="mailto:example@gmail.com">info@tableer.co</a>
                                </div>
                            </li>
                            <li>
                                <div className="img">
                                    <img src="/images/call_icon.png" alt="image" />
                                </div>
                                <div className="text">
                                    <span>{t("Call Us")}</span>
                                    <a href="tel:+1(888)553-46-11">+966 525531643</a>
                                </div>
                            </li>
                            <li>
                                <div className="img">
                                    <img src="/images/location_icon.png" alt="image" />
                                </div>
                                <div className="text">
                                    <span>{t("Visit Us")}</span>
                                    <p>{t("Riyadh, Saudi Arabia")}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>




    </>
}