const ReviewsScreen = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-4 col-md-6">
                    <div className="review_box">
                        <h3>Restaurant Name</h3>
                        <span>25/05/2023</span>
                        <div className="rating">
                            <ul>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                            </ul>
                        </div>
                        <p>
                            We recently had dinner with friends at Dimas Can Zheng and we all walked away with a great experience. Good food, pleasant environment, personal attention through all the evening. Thanks to the team and we will be back!
                        </p>
                        <div className="reviewer">
                            <div className="avtar">
                                <img src="images/review_01.png" alt="image" />
                            </div>
                            <div className="text">
                                <h3>Restaurant Name</h3>
                                <span>Riyadh tahlia st.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="review_box">

                        <h3>Restaurant Name</h3>
                        <span>25/05/2023</span>
                        <div className="rating">
                            <ul>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                            </ul>
                        </div>
                        <p>
                            We recently had dinner with friends at Dimas Can Zheng and we all walked away with a great experience. Good food, pleasant environment, personal attention through all the evening. Thanks to the team and we will be back!
                        </p>
                        <div className="reviewer">
                            <div className="avtar">
                                <img src="images/review_02.png" alt="image" />
                            </div>
                            <div className="text">
                                <h3>Restaurant Name</h3>
                                <span>Riyadh tahlia st.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="review_box">

                        <h3>Restaurant Name</h3>
                        <span>25/05/2023</span>
                        <div className="rating">
                            <ul>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                            </ul>
                        </div>
                        <p>
                            We recently had dinner with friends at Dimas Can Zheng and we all walked away with a great experience. Good food, pleasant environment, personal attention through all the evening. Thanks to the team and we will be back!
                        </p>
                        <div className="reviewer">
                            <div className="avtar">
                                <img src="images/review_03.png" alt="image" />
                            </div>
                            <div className="text">
                                <h3>Restaurant Name</h3>
                                <span>Riyadh tahlia st.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="review_box">

                        <h3>Restaurant Name</h3>
                        <span>25/05/2023</span>
                        <div className="rating">
                            <ul>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                            </ul>
                        </div>
                        <p>
                            We recently had dinner with friends at Dimas Can Zheng and we all walked away with a great experience. Good food, pleasant environment, personal attention through all the evening. Thanks to the team and we will be back!
                        </p>
                        <div className="reviewer">
                            <div className="avtar">
                                <img src="images/review_04.png" alt="image" />
                            </div>
                            <div className="text">
                                <h3>Restaurant Name</h3>
                                <span>Riyadh tahlia st.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="review_box">

                        <h3>Restaurant Name</h3>
                        <span>25/05/2023</span>
                        <div className="rating">
                            <ul>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                            </ul>
                        </div>
                        <p>
                            We recently had dinner with friends at Dimas Can Zheng and we all walked away with a great experience. Good food, pleasant environment, personal attention through all the evening. Thanks to the team and we will be back!
                        </p>
                        <div className="reviewer">
                            <div className="avtar">
                                <img src="images/review_05.png" alt="image" />
                            </div>
                            <div className="text">
                                <h3>Restaurant Name</h3>
                                <span>Riyadh tahlia st.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6">
                    <div className="review_box">

                        <h3>Restaurant Name</h3>
                        <span>25/05/2023</span>
                        <div className="rating">
                            <ul>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                                <li><span><i className="icofont-star"></i></span></li>
                            </ul>
                        </div>
                        <p>
                            We recently had dinner with friends at Dimas Can Zheng and we all walked away with a great experience. Good food, pleasant environment, personal attention through all the evening. Thanks to the team and we will be back!
                        </p>
                        <div className="reviewer">
                            <div className="avtar">
                                <img src="images/review_06.png" alt="image" />
                            </div>
                            <div className="text">
                                <h3>Restaurant Name</h3>
                                <span>Riyadh tahlia st.</span>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            <div className="pagination-con">
                <nav>
                    <ul className="pagination d-flex justify-content-center flex-wrap pagination-rounded-flat pagination-success">
                        <li className="page-item"><a className="page-link" href="#" data-abc="true"><i className="icofont-rounded-left"></i></a></li>
                        <li className="page-item active"><a className="page-link" href="#" data-abc="true">1</a></li>
                        <li className="page-item"><a className="page-link" href="#" data-abc="true">2</a></li>
                        <li className="page-item"><a className="page-link" href="#" data-abc="true">3</a></li>
                        <li className="page-item"><a className="page-link" href="#" data-abc="true">4</a></li>
                        <li className="page-item"><a className="page-link" href="#" data-abc="true"><i className="icofont-rounded-right"></i></a></li>
                    </ul>
                </nav>
            </div>
        </>
    );
}

export default ReviewsScreen;