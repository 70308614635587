import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router";
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../../helpers/formik";
import { AppCheckBox } from "../../components/formComponents/checkbox";
import { loginAPI, registerAPI } from "../../apis/auth";
import Cookies from "universal-cookie";
import moment from "moment";
import { EyeIcon } from "../../styles/eyeIcon";
import { CrossedEyeIcon } from "../../styles/crossedEyeIcon";
import { getLang } from "../../helpers/localizationHelper";
import { useSearchParams } from "react-router-dom";
import { resolveRequest } from "../../helpers";
import { GetAllCountries } from "../../apis/lookups";
import { LanguageMenu } from "../../components/header/languageMenu";

export const RegisterScreen = () => {
    const [showPassword, setShowPassword] = useState(true)

    const cookies = new Cookies()
    const [searchParams, setSearchParams] = useSearchParams();
    const returnUrl = searchParams?.get('returnUrl')
    const [allCountries, setAllCountries] = useState<any>()
    useEffect(() => {
        resolveRequest(GetAllCountries, setAllCountries)

    }, [getLang()])
    const [country, setCountry] = useState<any>()
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    // const [rememberMe, setRememberMe] = useState(false)    
    const submit = async (values: any) => {
        setLoading(true)
        try {
            const res = await (registerAPI({ ...values, dialCode: country }))
            if (res?.status) {
                cookies.set("token", `Bearer ${res?.token}`, { expires: moment(res?.tokenTime).toDate(), path: "/" })
                cookies.set("refreshToken", `${res?.refreshToken}`,{expires:moment(res?.refreshTokenTime).toDate(),path:"/"})                 
                if (returnUrl) {
                    window.location.replace(window.location.origin + window.location.href.split('returnUrl=')?.[1])
                } else navigate('/home')
            }
        } catch (e: any) {
            //setBackEndErrors(e?.response?.data?.errors, setFieldError)
        }
        setLoading(false)
    };

    const formik = useFormik(
        formikSetup(initialValues, undefined, validationSchema, 'register', submit),
    );
    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError } = formik;
    const isValid = formikFieldValidation(touched, errors);
    return <div className="full_bg">
        <div className="container">
            <section className="signup_section">
                <div className="d-flex w-100 justify-content-between my-4 align-items-baseline">
                    {/* <div dir={getLang() == "ar-SA" ? "rtl" : "ltr"}  className="top_part w-100"> */}
                        <a  dir={getLang() == "ar-SA" ? "rtl" : "ltr"}
                            className="back_btn"
                             style={{ cursor: 'pointer' }} onClick={() => {
                                navigate(-1)
                             }}>
                            {getLang() == "ar-SA" ? <i className="icofont-arrow-right"></i> : <i className="icofont-arrow-left"></i>} 
                            {t("back")}
                        </a>
                        <a className="navbar-brand m-0" href="#">
                            <img src="/images/footer_logo.png" alt="image" />
                        </a>
                    {/* </div> */}
                    <div dir={getLang() == "ar-SA" ? "rtl" : "ltr"} className='abs-container'>
                        <LanguageMenu />
                    </div>
                </div>
                <div className="signup_form">
                    <div className="section_title">
                        <h2> <span>{t('Create')}</span>{t("new account")}</h2>
                        <p>{t("Fill all fields so we can get some info about you.")} <br /> {t("We'll never send you spam")}</p>

                    </div>
                    <form className="mt-4" >

                        <div className="form-group">
                            <input value={values?.firstName} onChange={handleChange("firstName")} className="form-control" placeholder={t("firstName")} />
                            <p style={{ color: 'red', textAlign: 'start' }}>{isValid('firstName')}</p>

                        </div>
                        <div className="form-group">
                            <input value={values?.lastName} onChange={handleChange("lastName")} className="form-control" placeholder={t("lastName")} />
                            <p style={{ color: 'red', textAlign: 'start' }}>{isValid('lastName')}</p>

                        </div>
                        <div className="form-group">
                            <div style={{ display: 'flex' }}>
                                <select style={{ width: '50%', marginInlineEnd: 10 }} defaultValue={undefined} value={country} onChange={(e) => {
                                    setCountry(e.target.value)
                                }} className="form-control">{/* default-select  */}
                                    <option disabled selected value="">{t("Country")}</option>
                                    {allCountries && allCountries?.map((item: any) => {
                                        return <option key={item?.dialCode} value={item?.dialCode}>{item.fullCode}</option>
                                    })

                                    }
                                </select>
                                <input style={{ width: '50%' }} value={values?.mobile} onChange={handleChange("mobile")} className="form-control" placeholder={t("mobile")} />
                            </div>
                            <p style={{ color: 'red', textAlign: 'start' }}>{isValid('mobile')}</p>

                        </div>
                        <div className="form-group">
                            <input value={values?.email} onChange={handleChange("email")} className="form-control" placeholder={t("email")} />
                            <p style={{ color: 'red', textAlign: 'start' }}>{isValid('email')}</p>

                        </div>
                        <div className="form-group" style={{ position: 'relative' }}>
                            <input value={values?.password} onChange={handleChange("password")} type={!showPassword ? "text" : "password"} className="form-control" placeholder={t("password")} />
                            <div onClick={() => { setShowPassword(prev => !prev) }} style={{ position: 'absolute', top: 16, right: getLang() == "ar-SA" ? undefined : 10, left: getLang() == "ar-SA" ? 10 : undefined }}>
                                {!showPassword ? <CrossedEyeIcon color="gray" /> : <EyeIcon />
                                }        </div>
                            <p style={{ color: 'red', textAlign: 'start' }}>{isValid('password')}</p>

                        </div>
                        {/* <div className="form-group">
                            <AppCheckBox checked={rememberMe} onChange={(e) => {
                                setRememberMe(e.target.checked);
                            }} label={t("Remember me")} />
                        </div> */}
                        <div className="form-group">
                            <button className="btn puprple_btn" onClick={(e) => {
                                e.preventDefault()
                                handleSubmit?.()
                            }}>{t("signUp")}</button>
                        </div>
                    </form>
                    <p className="or_block">
                        <span>{t("OR")}</span>
                    </p>
                    <div className="or_option">
                        <p>{t("alreadyHaveAccount")}<a style={{ textDecoration: 'none' }} href="/login">{t("signInHere")}</a></p>
                    </div>
                </div>
            </section>
        </div>

    </div>
}