import DatePicker from "react-datepicker";

export const AppDatepicker = ({ className, label, time = false, value, onChange, placeholder, errorMessage, require = false, includeDates,labelClass }: { className?: string, require?: boolean, errorMessage?: string, time?: boolean, label?: string, placeholder?: string, value: any, onChange: (val: any) => void, includeDates?: any,labelClass?:any }) => {
    return <div className="col-12">
        {label &&
            <p className={`card-title ${labelClass}`}>
                                {label}
                {require ? <span style={{ color: 'red' }}> * </span> : null}{" "}
            </p>
        }

        <DatePicker
            showYearDropdown={true}
            placeholderText={placeholder}
            className={`form-control text-center ${require ?'border-red':''}`}
            wrapperClassName={` col-12 p-0 ${className}`}
            selected={value}
            dateFormat={"yyyy-MM-dd"}
            includeDates={includeDates}
            onChange={onChange}          
        />
    </div>
}