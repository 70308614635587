import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

interface SelectedItem {
    title: string,
    id: number | string
}
export const AppDropdownlist = ({ label, items, onChange, value, placeholder, require = false, errorMessage }: { errorMessage?: string, require?: boolean, label?: string, items: any | SelectedItem[], value: string | number, onChange: (val: any) => void, placeholder?: string }) => {
    const { t } = useTranslation()
    const [data, setData] = useState(items)
    useEffect(() => {

        setData(items)
    }, [items,])
    return <div className="col-12">

        <select defaultValue={undefined} value={value ?? null} onChange={onChange} className="form-control text-center wide mt-3">{/* default-select  */}
            <option disabled selected value="">{t(label ?? "choose")}</option>
            {data && data?.map((item: SelectedItem) => {
                return <option key={item?.id} value={item?.id}>{item.title}</option>
            })

            }
        </select>
        {errorMessage && (
            <p style={{ marginTop: 5, fontSize: 12, marginBottom: 5, color: "red" }}>
                {errorMessage}
            </p>
        )}
    </div>

}