import ComingSoon from "../../components/comingSoon";

const PointsScreen = () => {
    return (
        <>
            {/* <div className="profile-con">
                <div className="row">
                    <div className="col-lg-4 col-md-6 points-counter" id="counter">
                        <div className="icon">
                            <img src="images/points.gif" alt="image" />
                        </div>
                        <div className="text">
                            <p><span className="counter-value mypoints-number" data-count="59723">0</span></p>
                            <p>Total Points</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 points-counter" id="counter">
                        <div className="icon">
                            <img src="images/coin.gif" alt="image" />
                        </div>
                        <div className="text">
                            <p><span className="counter-value mypoints-number" data-count="59">0</span></p>
                            <p>Points Value SAR</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 points-counter" id="counter">
                        <div className="icon">
                            <img src="images/redeemed.gif" alt="image" />
                        </div>
                        <div className="text">
                            <p><span className="counter-value mypoints-number" data-count="2543">0</span></p>
                            <p>Redeemed Points</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="review_box">

                            <h3>Offer Title</h3>
                            <span>25/05/2023</span>

                            <p>
                                Offer details will be here to explain the details of the offer and conditions
                            </p>
                            <div className="reviewer">
                                <div className="avtar">
                                    <img src="images/review_01.png" alt="image" />
                                </div>
                                <div className="text">
                                    <h3>Restaurant Name</h3>
                                    <span>Riyadh tahlia st.</span>
                                </div>
                            </div>
                            <div className="redeem-cta mt-4">
                                <a href="#" className="btn white_btn">3000 - Redeem</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="review_box">

                            <h3>Offer Title</h3>
                            <span>25/05/2023</span>

                            <p>
                                Offer details will be here to explain the details of the offer and conditions
                            </p>
                            <div className="reviewer">
                                <div className="avtar">
                                    <img src="images/review_02.png" alt="image" />
                                </div>
                                <div className="text">
                                    <h3>Restaurant Name</h3>
                                    <span>Riyadh tahlia st.</span>
                                </div>
                            </div>
                            <div className="redeem-cta mt-4">
                                <a href="#" className="btn white_btn">3000 - Redeem</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="review_box">

                            <h3>Offer Title</h3>
                            <span>25/05/2023</span>

                            <p>
                                Offer details will be here to explain the details of the offer and conditions
                            </p>
                            <div className="reviewer">
                                <div className="avtar">
                                    <img src="images/review_03.png" alt="image" />
                                </div>
                                <div className="text">
                                    <h3>Restaurant Name</h3>
                                    <span>Riyadh tahlia st.</span>
                                </div>
                            </div>
                            <div className="redeem-cta mt-4">
                                <a href="#" className="btn white_btn">3000 - Redeem</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pagination-con">
                    <nav>
                        <ul className="pagination d-flex justify-content-center flex-wrap pagination-rounded-flat pagination-success">
                            <li className="page-item"><a className="page-link" href="#" data-abc="true"><i className="icofont-rounded-left"></i></a></li>
                            <li className="page-item active"><a className="page-link" href="#" data-abc="true">1</a></li>
                            <li className="page-item"><a className="page-link" href="#" data-abc="true">2</a></li>
                            <li className="page-item"><a className="page-link" href="#" data-abc="true">3</a></li>
                            <li className="page-item"><a className="page-link" href="#" data-abc="true">4</a></li>
                            <li className="page-item"><a className="page-link" href="#" data-abc="true"><i className="icofont-rounded-right"></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div> */}
            <ComingSoon />
        </>
    );
}

export default PointsScreen;
