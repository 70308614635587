import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"

export const AppModal = ({ className,saveBtnLabel, closeBtnLabel, closeClass, onSaveClick, title, body, show, onClose }: {className?: string, closeBtnLabel?: string, closeClass?: string, show: boolean, body: any, saveBtnLabel?: string, onSaveClick?: () => void, title?: string, onClose?: any}) => {
    const { t } = useTranslation()
    return <>
        <Modal
            show={show} onHide={onClose} 
            dialogClassName={className}>
            <div className="modal-header p-3 d-flex justify-content-between">
                <h5 className="modal-title">{title}</h5>
                <button type="button" onClick={onClose}
                    className="btn text-muted" data-bs-dismiss="modal">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#6c757d" height="1em" viewBox="0 0 352 512">
                        <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" /></svg>
                </button>
            </div>
            {body && <div style={{ fontSize: 20 }} className="modal-body">{body}</div>}
                <div className="modal-footer justify-content-end">
                    <button onClick={onClose} type="button"
                        className={`btn light ${closeClass}`} data-bs-dismiss="modal">
                        {closeBtnLabel ?? t("close")}
                    </button>
                </div>
        </Modal>
    </>
}