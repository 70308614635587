import { ReactNode } from "react"
import TabHeader from "./tabHeader"
export const TabLayout = ({ children }: { children: ReactNode }) => {
    return <>
        <section>
            <TabHeader />
            <div className="container">
                {children}
            </div>
        </section>
    </>
}