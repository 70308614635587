const ComingSoon = () => {
    return ( <>
        <div className="p-4 mb-4 rounded d-flex justify-content-center bg-white flex-column align-items-center">
            <div>
            <img src="images/points.gif" alt="" />
            </div>
            <div className="mt-3 w-100 text-center">
                <h4>Coming Soon</h4>
            </div>
        </div>
    </> );
}
 
export default ComingSoon;