import { useEffect } from 'react';
import { changeLang, getLang, } from './helpers/localizationHelper';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { store } from './redux';
import AppRouting from './routing';
import { Helmet } from 'react-helmet';

const App = () => {
  const { i18n } = useTranslation()

  useEffect(() => {
    changeLang(getLang() ?? 'en-US')
  }, [i18n])

  useEffect(() => {
    if (getLang()) {
      if (getLang() == "ar-SA") {
        document.body.style.direction = "rtl";

      } else {
        document.body.style.direction = "ltr";
        if (document.getElementById('arabic'))
          document.getElementById('arabic')?.remove()

      }
    }
  }, []);

  return (
    <>
      <Helmet>

        {
          getLang() == "ar-SA" && <link href="/css/style-rtl.css" id="arabic" rel="stylesheet" />
        }
      </Helmet>
      <div >

        <Provider store={store}>
          <AppRouting />
        </Provider>
      </div></>
  );
}

export default App;
