import { ErrorToast } from "../../helpers/toastify";
import history from "../../routing/historyObj";

export const interceptorsErrorsResponse = (error) => {
  switch (error?.response?.status) {
    case 422:
      return ErrorToast(error?.response?.data?.message);
    // case 401:
    //   return ErrorToast(error?.response?.data?.message);
    case 403:
      return ErrorToast(error?.response?.data?.message);
    case 409:
      return ErrorToast(error?.response?.data?.message);
    case 400:
      if (!window.location.pathname.includes("survey")) {
        return ErrorToast(error?.response?.data?.message);
      } else {
        return "";
      }
    case 404:
      return ErrorToast(error?.response?.data?.message);
    case 500:
      return ErrorToast(error?.response?.data?.message);
    case 503:
      return ErrorToast(error?.response?.data?.message);
    case 502:
      return "";
    default:
      ErrorToast("Something Went Wrong");
  }
};
