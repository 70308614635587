import { useTranslation } from "react-i18next";

const TabHeader = () => {
    const { t } = useTranslation()

    return (
        <>
            <div className="bred_crumb">
                <div className="container">
                    <span className="banner_shape1"> <img src="/images/banner-shape1.png" alt="image" /> </span>
                    <span className="banner_shape2"> <img src="/images/banner-shape2.png" alt="image" /> </span>
                    <span className="banner_shape3"> <img src="/images/banner-shape3.png" alt="image" /> </span>

                    <div className="bred_text">
                        <h1>
                            {window.location.pathname.includes("myProfile") && t("myProfile")}
                            {window.location.pathname.includes("myReservations") && t("myReservations")}
                            {window.location.pathname.includes("myPoints") && t("mypoints")}
                            {window.location.pathname.includes("myPayments") && t("myPayments")}
                            {window.location.pathname.includes("myReviews") && t("myReviews")}
                            
                            {window.location.pathname.includes("changePassword") && t("changePassword")}

                        </h1>

                        <ul>
                            <li><a href="/home">{t("home")}</a></li>
                            <li><span>»</span></li>
                            {
                                window.location.pathname.includes("myProfile") &&
                                <li>
                                    <a href="/myProfile">{t("myProfile")}</a>
                                </li>
                            }
                            {
                                window.location.pathname.includes("myReservations") &&
                                <li>
                                    <a href="/myReservations">{t("myReservations")}</a>
                                </li>
                            }
                            {
                                window.location.pathname.includes("myPoints") &&
                                <li>
                                    <a href="/myPoints">{t("mypoints")}</a>
                                </li>
                            }
                            {
                                window.location.pathname.includes("myPayments") &&
                                <li>
                                    <a href="/myPayments">{t("myPayments")}</a>
                                </li>
                            }
                            {
                                window.location.pathname.includes("myReviews") &&
                                <li>
                                    <a href="/myReviews">{t("myReviews")}</a>
                                </li>
                            }
                              {
                                window.location.pathname.includes("changePassword") &&
                                <li>
                                    <a href="/changePassword">{t("changePassword")}</a>
                                </li>
                            }

                        </ul>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row profile-nav">
                    <ul className="nav nav-pills nav-justified">
                        <li className="nav-item">
                            <a className={`nav-link text-nowrap ${window.location.pathname.includes("myProfile") ? 'active' : ''} `}
                                aria-current="page" href="/myProfile">
                                {t("profile")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link text-nowrap ${window.location.pathname.includes("myReservations") ? 'active' : ''} `} 
                                href="/myReservations">
                                {t("myReservations")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link text-nowrap ${window.location.pathname.includes("myPoints") ? 'active' : ''} `} 
                                 href="/myPoints">
                                {t("mypoints")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link text-nowrap ${window.location.pathname.includes("myPayments") ? 'active' : ''} `} 
                               href="/myPayments">
                            {t("myPayments")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link text-nowrap ${window.location.pathname.includes("myReviews") ? 'active' : ''} `} 
                                 href="/myReviews">
                                {t("myReviews")}
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link text-nowrap ${window.location.pathname.includes("changePassword") ? 'active' : ''} `} 
                                 href="/changePassword">
                                {t("changePassword")}
                            </a>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </>);
}

export default TabHeader;