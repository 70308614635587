import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useParams, } from 'react-router'
import { resolveRequest } from '../../helpers'
import { addReservation, detailsRestaurant, listingBranches, workingSlots, workingSystems } from '../../apis/restaurants'
import { AppDatepicker } from '../../components/formComponents/datePicker'
import { AppDropdownlist } from '../../components/formComponents/dropdownlist'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { getLang } from '../../helpers/localizationHelper'
import Cookies from 'universal-cookie'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { baseURLPhotos } from '../../apis/endpoints'
import { ProfileMenu } from '../../components/header/profileMenu'
import { LanguageMenu } from '../../components/header/languageMenu'
import { Loader } from '../../components/loader'
import NoDataIcon from '../../styles/noDataIcon'
export const BookingDetailsScreen = () => {
    const { t } = useTranslation()
    const [isSuccessful, setIsSuccessful] = useState(false)
    const cookies = new Cookies()
    const isAuth = cookies.get('token')
    const [screenType, setScreenType] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const { id } = useParams()
    const tabs = [
        { id: 1, name: t('default') },
        { id: 2, name: t('waiting') }
    ]
    const [currentTab, setCurrentTab] = useState(1)
    const [currentSlot, setCurrentSlot] = useState<any>()
    const [max, setMax] = useState(1)
    const [restaurantDetails, setRestaurantDetails] = useState<any>()
    const [branches, setBranches] = useState<any>()
    const [branch, setBranch] = useState<any>()
    const [workingDays, setWorkingDays] = useState<any>()
    const [timeSlots, setTimeSlots] = useState<any>()
    const [date, setDate] = useState<any>(null)
    const [isLoading, setLoading] = useState(false)
    useEffect(() => {
        resolveRequest(detailsRestaurant, setRestaurantDetails, { id: id })
        resolveRequest(listingBranches, setBranches, { id: id })
        setLoading(true)
    }, [id])
    useEffect(() => {
        if (branches?.length == 1) {
            setBranch(branches?.[0]?.id)
        }
    }, [branches])
    useEffect(() => {
        if (searchParams?.get('branchId')) {
            if (branches) {
                if (branches?.filter((item: any) => item?.id == searchParams?.get('branchId')).length == 0) {
                    setScreenType(false)
                    setSearchParams({})
                }
                else {
                    setBranch(searchParams?.get('branchId'))
                }
            }
        }
    }, [branches, searchParams?.get('branchId')])


    useEffect(() => {
        //@ts-ignore
        if (branch && date && tabs.filter(item => item?.id == parseInt(searchParams?.get('currentTab'))).length > 0)
            setCurrentTab(parseInt(searchParams?.get('currentTab') ?? "1"))
        else {
            setCurrentTab(1)
            setCurrentSlot(undefined)
        }
    }, [branch, date, searchParams?.get('currentTab')])
    useEffect(() => {
        if (branch)
            resolveRequest(workingSystems, setWorkingDays, { id: branch })
    }, [branch, id])

    useEffect(() => {
        if (date && branch)
            resolveRequest(workingSlots, setTimeSlots, { id: branch, partySize: max, selectedDate: moment(date).format("YYYY-MM-DDT00:00:00.000") 
            })
    }, [date, branch])

    useEffect(() => {
        if (searchParams?.get('branchId') != undefined) {
            setScreenType(true)
        }
    }, [searchParams?.get('branchId')])
    const lang = useSelector((state: any) => state?.language?.getLang)
    return <>
        <Helmet>
            <link rel='stylesheet' href='/css/style-booking.css' />
            <link rel='stylesheet' href='/css/myStyle.css' />
            <link rel="stylesheet" href="/css/material-design-iconic-font.min.css" />
            <link rel="stylesheet" href="/css/icofont.min.css" />
            <script src="/js/jquery-3.6.0.min"></script>
            <script src="/js/cdn.jsdelivr.net_npm_bootstrap@5.1.1_dist_js_bootstrap.bundle.min.js"></script>
            {getLang() == "ar-SA" && <link href="/css/style-rtl.css" id="arabic" rel="stylesheet" />}
        </Helmet>
        {restaurantDetails?.data != undefined ? <>
        <div style={{
               paddingBlock: "61px 0px",
                paddingInline: 25,
                backgroundImage: `url(${restaurantDetails?.data?.bannerImage ? (baseURLPhotos + "/" + restaurantDetails?.data?.bannerImage) : '/bg.jpg'})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                minHeight: '100vh'
            }}>
            <div className='d-flex justify-content-between'>
            <a dir={getLang() == "ar-SA" ? "rtl" : "ltr"} style={{ cursor: 'pointer' }} onClick={() => {
                navigate("/home")
                window.location.reload()
            }} className="back text-white">
                {getLang() == "ar-SA" ? <i className="icofont-arrow-right"></i> : <i className="icofont-arrow-left"></i>} 
                {t("back")}
            </a>
            <div dir={getLang() == "ar-SA" ? "rtl" : "ltr"} className='abs-container'>
                <ProfileMenu />
                <LanguageMenu />
            </div>
            </div>
            <div  className="main">
                <section className="signup">
                    <div className="rest-cont">
                        <div className="logo largeLogo">
                            <img src={`${restaurantDetails?.data?.logoImage ? (baseURLPhotos + "/" + restaurantDetails?.data?.logoImage) : '/images/logo-preloader.png'}`} alt="vendor-logo" />
                        </div>
                    </div>
                    <div className="container">
                        <div className="signup-content">
                            {isSuccessful == false && <>
                                {screenType == false && <>
                                    <div className='btnsContainer'>
                                        <a target='_blank' href={restaurantDetails?.data?.menuUrl} className="button"
                                            style={{
                                                backgroundImage: `linear-gradient(to left,${restaurantDetails?.data?.primaryColor == null || restaurantDetails?.data?.primaryColor == "" || restaurantDetails?.data?.primaryColor == "transparent" ? "#6A49F2" : restaurantDetails?.data?.primaryColor},${restaurantDetails?.data?.secondaryColor == null || restaurantDetails?.data?.secondaryColor == "" || restaurantDetails?.data?.secondaryColor == "transparent" ? '#B760EA' : restaurantDetails?.data?.secondaryColor})`,
                                                color: "white"
                                            }} onClick={async () => {
                                            }}>
                                            <img src='/menu.png' height={50} width={50} />
                                            {t("download_menu")}
                                        </a>
                                        <div className="button"
                                            style={{
                                                backgroundImage: `linear-gradient(to left,${restaurantDetails?.data?.primaryColor == null || restaurantDetails?.data?.primaryColor == "" || restaurantDetails?.data?.primaryColor == "transparent" ? "#6A49F2" : restaurantDetails?.data?.primaryColor},${restaurantDetails?.data?.secondaryColor == null || restaurantDetails?.data?.secondaryColor == "" || restaurantDetails?.data?.secondaryColor == "transparent" ? '#B760EA' : restaurantDetails?.data?.secondaryColor})`,
                                                color: "white"
                                            }}
                                            onClick={() => {
                                                setScreenType(true)
                                            }}>
                                            <img src='/booking.png' height={50} width={50} />
                                            {t("bookNow")}
                                        </div>
                                    </div>
                                </>
                                }
                                {screenType == true &&
                                    <form id="signup-form" className="signup-form">
                                        <h2 className="form-title mt-4 booking_resturant_title">{restaurantDetails?.data?.title}</h2>
                                        <div >
                                            {
                                                branches?.length == 0 &&
                                                <p className='text-center'>
                                                    {t("no_branches_available")}
                                                </p>
                                            }
                                            {/* searchParams?.get('branchId') != undefined ? <p className='text-center'>{branches?.filter((item: any) => item?.id == searchParams?.get('branchId'))?.[0]?.title}</p>: */
                                                (branches?.length == 1 ? <p className='text-center'>{branches?.[0]?.title}</p> :
                                                    (branches?.length > 1 && <div style={{ marginInline: 0 }} className=" mb-0 row justify-content-sm-center justify-content-start ">
                                                        <AppDropdownlist label={t("branches")} items={branches} value={branch} onChange={(e) => {
                                                            setMax(1)
                                                            setWorkingDays(undefined)
                                                            setDate(undefined)
                                                            setTimeSlots(undefined)
                                                            setCurrentSlot(undefined)
                                                            searchParams.delete("date")
                                                            searchParams.delete("currentTab")
                                                            searchParams.delete("currentSlot")
                                                            searchParams.delete("max")
                                                            setBranch(e?.target?.value)
                                                            searchParams.set("branchId", e?.target?.value)
                                                            setSearchParams(searchParams)
                                                        }} />
                                                    </div>)
                                                )
                                            }


                                            {workingDays && ((branch && workingDays?.length > 0) ?
                                                <div style={{ marginInline: 0 }} className="mb-0 mt-3 row justify-content-sm-center justify-content-start ">


                                                    <AppDatepicker label={t("available_dates")}
                                                        labelClass="text-center"
                                                        includeDates={workingDays?.map((item: any) => {
                                                            return new Date(item)
                                                        })}
                                                        onChange={(e) => {
                                                            setDate(e)
                                                            searchParams.set("date", e)
                                                            // searchParams.delete("max")
                                                            searchParams.delete("currentTab")
                                                            // searchParams.delete("max")
                                                            searchParams.delete("currentSlot")
                                                            // setMax(1)
                                                            setCurrentTab(1)
                                                            setSearchParams(searchParams)
                                                            // setDateRequire(false)
                                                        }}
                                                        value={date}
                                                    />
                                                    <div className='w-100'>
                                                        <p className='text-center mt-3'>{t("numOfGuests")}</p>
                                                        <div className="d-flex align-items-center">
                                                            <div onClick={() => {
                                                                // if (max < timeSlots?.maxGuestsNumberPerReservation) {
                                                                setMax(prev => prev + 1)
                                                                // searchParams.set("max", (max + 1).toString())
                                                                // setSearchParams(searchParams)

                                                                // }
                                                                if (date != null) {
                                                                    resolveRequest(workingSlots, setTimeSlots, { id: branch, partySize: max + 1, selectedDate: moment(date).format("YYYY-MM-DDTHH:mm:ssZ") })
                                                                }

                                                            }} className='cell p-3'>
                                                                +
                                                            </div>
                                                            <input className="form-input text-center" value={max} max={timeSlots?.maxGuestsNumberPerReservation}
                                                            />
                                                            <div onClick={() => {
                                                                if (max > 1) {
                                                                    setMax(prev => prev - 1)
                                                                    // searchParams.set("max", (max - 1).toString())
                                                                    // setSearchParams(searchParams)
                                                                    if (date != null) {
                                                                        resolveRequest(workingSlots, setTimeSlots, { id: branch, partySize: max - 1, selectedDate: moment(date).format("YYYY-MM-DDTHH:mm:ssZ") })
                                                                    }
                                                                    // else{
                                                                    //     setDateRequire(true)
                                                                    // }
                                                                }
                                                            }}
                                                                className='cell p-3'>
                                                                -
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : <p className='text-center'>{t("no_dates_available")}</p>)}


                                        </div>
                                        {Object.keys(timeSlots ?? {})?.length
                                            &&
                                            timeSlots?.availabelSlots?.length != 0
                                            ? <>
                                                <div className="d-flex justify-content-center flex-wrap mt-2" style={{ borderBottomWidth: 1, borderBottomStyle: 'solid', padding: 0, marginBottom: 20 }}>
                                                    {tabs?.map((item => {
                                                        return <p style={{
                                                            marginBlock: 0,
                                                            color: item?.id == currentTab ? "#886bff" : "black", borderBottomColor: item?.id == currentTab ? "#886bff" : "transparent", borderBottomWidth: 1, borderBottomStyle: 'solid', cursor: 'pointer', paddingInline: 20
                                                        }} onClick={() => {
                                                            setCurrentTab(item?.id)
                                                            setCurrentSlot(null)
                                                            searchParams.delete("currentSlot")
                                                            searchParams.set("currentTab", item?.id?.toString())
                                                            setSearchParams(searchParams)
                                                        }}>{item?.name}</p>
                                                    }))}
                                                </div>

                                                {currentTab == 1 &&
                                                    <div className="form-group">
                                                        <div className="tab-container" style={{ overflowY: 'visible' }}>
                                                            <div className="row flex-wrap text-center mx-0 mt-2 mb-2">
                                                                {timeSlots?.availabelSlots?.length ? timeSlots?.availabelSlots?.map((item: any) => {
                                                                    return <div onClick={() => {
                                                                        setCurrentSlot(item)
                                                                        searchParams.set("currentSlot", item)
                                                                        setSearchParams(searchParams)
                                                                    }} className="col-md-2 col-4 my-1 px-1" >
                                                                        <div className="cell py-1" style={{ backgroundColor: item == currentSlot ? (restaurantDetails?.data?.primaryColor == "" || restaurantDetails?.data?.primaryColor == null || restaurantDetails?.data?.primaryColor == "transparent" ? "#6A49F2" : restaurantDetails?.data?.primaryColor) : "white", color: item != currentSlot ? restaurantDetails?.data?.primaryColor : "white" }}>
                                                                            {item.appointment}</div>
                                                                    </div>
                                                                }) :  <div className="bg-white d-flex justify-content-center align-items-center p-2 w-100 mb-2 rounded">
                                                                    <p>{t("no_timeSlots_available")}</p>
                                                                    </div>}
                                                            </div>

                                                        </div>
                                                    </div>
                                                }
                                                {currentTab == 2 &&
                                                    <div className="form-group">
                                                        <div className="tab-container">
                                                            <div className="row flex-wrap text-center mx-0 mt-2 mb-2">
                                                                {timeSlots?.allowWaitingList ? timeSlots?.waitingAvailabelSlots?.map((item: any) => {
                                                                    return <div onClick={() => {
                                                                        setCurrentSlot(item)
                                                                        searchParams.set("currentSlot", item)
                                                                        setSearchParams(searchParams)
                                                                    }} className="col-md-2 col-4 my-1 px-1">
                                                                        <div className="cell py-1" style={{ backgroundColor: item == currentSlot ? (restaurantDetails?.data?.primaryColor == "" || restaurantDetails?.data?.primaryColor == null || restaurantDetails?.data?.primaryColor == "transparent" ? "#6A49F2" : restaurantDetails?.data?.primaryColor) : "white", color: item != currentSlot ? restaurantDetails?.data?.primaryColor : "white" }}>{item}</div>
                                                                    </div>
                                                                }) :<div className="bg-white d-flex justify-content-center align-items-center p-2 w-100 mb-2 rounded">
                                                                    <p>{t("no_timeSlots_available")}</p>
                                                                    </div>}
                                                            </div>

                                                        </div>


                                                    </div>
                                                }
                                                <div className="form-group">
                                                    {isAuth && <div className="btn  w-100 d-block h8" style={{
                                                        backgroundImage: currentSlot ? `linear-gradient(to left,${restaurantDetails?.data?.primaryColor == "" || restaurantDetails?.data?.primaryColor == null || restaurantDetails?.data?.primaryColor == "white" ? "#6A49F2" : restaurantDetails?.data?.primaryColor},${restaurantDetails?.data?.secondaryColor == "" || restaurantDetails?.data?.secondaryColor == null || restaurantDetails?.data?.secondaryColor == "white" ? '#B760EA' : restaurantDetails?.data?.secondaryColor})` : undefined,
                                                        backgroundColor: currentSlot == undefined ? "gray" : undefined,
                                                        color: "white"

                                                    }} onClick={async () => {
                                                        if (currentSlot) {
                                                            const res = await addReservation({
                                                                appointmentSlotId: currentSlot.id,
                                                                branchId: branch,
                                                                //  dateTimeSlot: `${moment(date).format("YYYY-MM-DD")}T${moment(currentSlot?.appointment.replace("ص", "AM")?.replace("م", "PM"), 'h:mm A').format("HH:mm:ss")}Z`, 
                                                                partySize: max,
                                                                isWaiting: currentTab == 2 ? true : false
                                                            })
                                                            if (res?.status) {
                                                                setIsSuccessful(true)
                                                                setTimeout(() => {
                                                                    navigate("/myReservations")
                                                                    window.location.reload()
                                                                }, 8000);
                                                            }
                                                        }

                                                    }}>{t("RESERVE")}</div>}
                                                    {!isAuth &&
                                                        <div className="btn  w-100 d-block h8" style={{
                                                            backgroundImage: `linear-gradient(to left,${restaurantDetails?.data?.primaryColor == "" || restaurantDetails?.data?.primaryColor == null || restaurantDetails?.data?.primaryColor == "white" ? "#6A49F2" : restaurantDetails?.data?.primaryColor},${restaurantDetails?.data?.secondaryColor == "" || restaurantDetails?.data?.secondaryColor == null || restaurantDetails?.data?.secondaryColor == "white" ? '#B760EA' : restaurantDetails?.data?.secondaryColor})`,
                                                            color: "white"

                                                        }} onClick={async () => {
                                                            //  localStorage.setItem('history', window.location.href)

                                                            navigate('/login' + `?returnUrl=${window.location.href?.replace(window.location.origin, '')}`)
                                                        }}>{t("LoginToReserve")}</div>
                                                    }

                                                </div>
                                            </> : timeSlots != undefined ? <p className='text-center'><div className="bg-white d-flex justify-content-center align-items-center p-2 w-100 mb-2 rounded">
                                            <p>{t("no_timeSlots_available")}</p>
                                            </div></p> : ""
                                            //  : timeSlots?.maxGuestsNumberPerReservation == 0 ? <p className='text-center'>{t("no_timeSlots_available")}</p> : <></>
                                        }
                                        <p onClick={() => {
                                            setBranch(undefined)
                                            setDate(undefined)
                                            setWorkingDays(undefined)
                                            setTimeSlots(undefined)
                                            // setMax(1)
                                            setCurrentTab(1)
                                            setCurrentSlot(undefined)
                                            setScreenType(false)
                                            setSearchParams({})


                                        }} style={{ textDecoration: 'underline', color: "#6A49F2", fontSize: 16, textUnderlineOffset: 6, textAlign: 'center', marginTop: 40, cursor: 'pointer', fontFamily: "'Almarai', 'sans-serif'" }}>
                                            {t("Back to restaurant")}</p>

                                    </form>}
                            </>}
                            {isSuccessful == true && <>
                                <h2 className="form-title">{t("successful")}</h2>
                                <div className="successful-icon"><img src="/images/success.gif" alt="vendor-logo" /></div>
                                <p className="textmuted h5 mb-2 text-center">{t("You successfully created your booking")}</p>
                            </>}
                        </div>
                    </div>
                    <div className="tableer-footer">
                        <p className="tableer-footer-p">
                            {t("Powered by")}

                        </p>
                        <a href="https://tableer.co" className="tableer-footer-link">
                            <img src="/images/tableer-footer-logo.png" alt="vendor-logo" />
                        </a>
                    </div>
                </section>
            </div>
            </div>
        </> : <Loader />}
        {/* {isLoading && <Loader />} */}
    </>
}