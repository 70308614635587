import { ReactNode } from "react"
import { Header } from "./header"
import { Footer } from "./footer"
import { Helmet } from "react-helmet"

export const Layout = ({ children }: { children: ReactNode }) => {

    return <>
        <Helmet>


            <script src="/js/main.js"></script>
        </Helmet>
        <div className="page_wrapper">
            {!window.location.pathname.includes("bookingDetails") && !window.location.pathname.includes("login") && !window.location.pathname.includes("survey") && !window.location.pathname.includes("register") && !window.location.pathname.includes("forgetPassword")&& !window.location.pathname.includes("ReservationsDetails") && <Header />}
            {children}
            {!window.location.pathname.includes('bookingDetails') && !window.location.pathname.includes("login") && !window.location.pathname.includes("survey") && !window.location.pathname.includes("register") && !window.location.pathname.includes("forgetPassword") && !window.location.pathname.includes("ReservationsDetails") && <Footer />}        
        </div>
    </>
}