import axiosCall from "../axios/axiosConfig";
import { endpoints } from "../endpoints";

export const GetAllLanguages = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.lookups.languages)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject({ status: false, message: res?.data?.message });
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const GetAllCountries = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.lookups.countries)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject({ status: false, message: res?.data?.message });
      })
      .catch((e) => {
        reject(e);
      });
  });
};
