import { useEffect, useState } from "react"
import { BannerSection } from "./components/bannerSection"
import { RestaurantsSection } from "./components/restaurantsSection"
import { listingRestaurant } from "../../apis/restaurants"
import { FeaturesSection } from "./components/featuresSection"
import { AboutSection } from "./components/aboutSection"
import { PricingSection } from "./components/pricingSection"

export const HomeScreen = () => {
    const [allRestaurants, setAllRestaurants] = useState<any>()
    const [isLoading, setIsLoading] = useState<any>(undefined)
    useEffect(() => {
        (async () => {
            setIsLoading(true)

            const res = await listingRestaurant()
            setAllRestaurants(res)
            setIsLoading(false)

        })()
        if (window.location.href?.includes("features")) {
            if (document.getElementById("features")) {
                document.getElementById("features")?.scrollIntoView({ behavior: "smooth", block: 'end' })
            }
        }
        if (window.location.href?.includes("pricing")) {
            if (document.getElementById("pricing")) {
                document.getElementById("pricing")?.scrollIntoView({ behavior: "smooth", block: 'end' })
            }
        }
    }, [])
    return <>

        <BannerSection />
        <RestaurantsSection allRestaurants={allRestaurants} />
        <FeaturesSection />
        <AboutSection />
        <PricingSection />

    </>
}