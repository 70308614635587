import { applyMiddleware, createStore } from "redux";
import promiseMiddleware from "redux-promise";
import { persistStore, persistReducer } from "redux-persist";
import { root } from "./reducers";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["user"],
};
const createStoreWithMW = applyMiddleware(promiseMiddleware)(createStore);
const persistedReducer = persistReducer(persistConfig, root);
export const store = createStoreWithMW(persistedReducer);
export const persistor = persistStore(store);
