import { useState } from "react";
import { updatePassword } from "../../apis/userProfile";
import { useFormik } from "formik";
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../../helpers/formik";
import { useTranslation } from "react-i18next";
import { Loader } from "../../components/loader";

export const ChangePasswordScreen = () => {
    const [isLoading, setLoading] = useState(false)
    const { t } = useTranslation()

    const submit2 = async (values: any) => {
        try {
            setLoading(true)
            const res = await updatePassword({ data: values })
            if (res?.status) {
                resetForm({ values: {} })
            }
            setLoading(false)

        } catch (e: any) {
            setLoading(false)

            //setBackEndErrors(e?.response?.data?.errors, setFieldError)
        }
    };
    const formik2 = useFormik(
        formikSetup(initialValues, undefined, validationSchema, 'changePassword', submit2),
    );
    const { handleChange: handleChange2, handleSubmit: handleSubmit2, values: values2, touched: touched2, errors: errors2, resetForm } = formik2;
    const isValid2 = formikFieldValidation(touched2, errors2);

    return <>
    
        <div className="profile-section">
            <div className="container">
                <div className="profile-con mb-4">
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} className="mt-2">
                        <div style={{ alignSelf: 'flex-start' }} className="section_title2  mb-4">
                            <h4>{t("changePassword")}</h4>
                        </div>
                        <div className="col-md-6">
                            <label className="labels">
                            {t("currentPassword")}
                              <span className="text-danger  mx-1">*</span>
                            </label>
                            <input value={values2?.currentPassword}
                             style={{ border: isValid2('currentPassword') ? '1px solid red' : '' ,
                             borderRadius: "8px",
                             padding: "19px"}}
                             onChange={handleChange2("currentPassword")}  type="password" className="form-control" />
                        </div>
                        <div className="col-md-6 mt-3">
                            <label className="labels">
                              {t("newPassword")}
                              <span className="text-danger  mx-1">*</span>
                           </label>
                            <input value={values2?.newPassword} 
                            style={{border: isValid2('newPassword') ? '1px solid red':'',borderRadius: "8px",
                            padding: "19px"}} 
                            onChange={handleChange2("newPassword")} type="password" className="form-control" />
                        </div>
                        <div className="col-md-6 mt-3">
                            <label className="labels">
                                {t("confirmPassword")}
                                <span className="text-danger  mx-1">*</span>
                            </label>
                            <input value={values2?.confirmPassword} 
                            style={{border: isValid2('confirmPassword')?'1px solid red':'',borderRadius: "8px",
                            padding: "19px"}}
                            onChange={handleChange2("confirmPassword")} type="password" className="form-control" />
                        </div>
                    </div>
                    {isLoading == false ? <div style={{ zIndex: -1 }} className={`mt-4  text-center ml-3   `}><button className="btn puprple_btn aos-init aos-animate" type="button"
                        //@ts-ignore
                        onClick={handleSubmit2}>{t("save")}</button>

                    </div> : <Loader />}
                </div>
            </div>

        </div></>
}