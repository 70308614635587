import QRCode from "react-qr-code";
const QrCodeComponent = ({ value }: { value: any }) => {  
    return (<>
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 190, width: "100%" }}>
    
            <a href="/home">
                <QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={value}
                    viewBox={`0 0 256 256`} 
                />
            </a>
        </div></>);
}

export default QrCodeComponent;