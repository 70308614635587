import Cookies from "universal-cookie";
import { Layout } from "../components/layout";
import { Navigate } from "react-router";
import { LoginScreen } from "../screens/auth/loginScreen";

const PublicRoute = ({ children }) => {
  let isAuth = "";
  let isRefreshAuth="";

  const cookies = new Cookies();

  isAuth = cookies.get("token");
  isRefreshAuth = cookies.get("refreshToken");

  //reverse conditions here
  if (
    isAuth == undefined &&
    (window.location.pathname.includes("myProfile") ||
      window.location.pathname.includes("myReservations") ||
      window.location.pathname.includes("changePassword")||
      window.location.pathname.includes("myPoints")||
      window.location.pathname.includes("myPayments")||
      window.location.pathname.includes("myReviews")) 
  ) {
    return <Layout>{children}</Layout>;
  } else if (
    isAuth == undefined &&
    (window.location.pathname.includes("login") ||
      window.location.pathname.includes("register"))
  ) {
    return children;
  } else if (
    !window.location.pathname.includes("login") &&
    !window.location.pathname.includes("register")
  ) {
    return <Layout>{children}</Layout>;
  } else if (
    isAuth != undefined &&
    (window.location.pathname.includes("login") ||
      window.location.pathname.includes("register"))
  ) {
    return <Navigate to={"/"} />;
  }else{
    return <Navigate to={"/"} />;
  }
};

export default PublicRoute;
