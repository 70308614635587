
import * as Yup from "yup";
import {parseISO } from 'date-fns'
import ar from '../translations/ar/translation.json'
import en from '../translations/en/translation.json'
import { getLang } from "./localizationHelper";

export const formikFieldValidation = (touched: object, errors: object) => {
    return (fieldName: string) => {
        //@ts-ignore
        return touched[fieldName] && errors[fieldName] ? errors[fieldName] : false;
    };
};
export const setBackEndErrors = (errorsObject: object, setErrorFunction: (key: string, value: string) => void) => {
    for (let [key, value] of Object.entries(errorsObject ?? {})) {
        //value[0]
        setErrorFunction(key, value[0] );
    }
};



const phoneNumberPattern = (/(5)[0-9]{8}/)

const phoneNumberRequire =Yup.string().required('fsf').matches(phoneNumberPattern, "رقم الجوال غير صحيح").max(9, 'يلزم ان يكون ٩ ارقام').min(9, 'يلزم ان يكون ٩ ارقام')

export const validationSchema = () => {
    return {
        login: {
            "email": Yup.string().required(getLang() == "ar-SA" ? ar.email_required : en.email_required),
            "password": Yup.string().required(getLang() == "ar-SA" ? ar.password_required : en.password_required),
            

        },
        register: {
            "firstName": Yup.string().required(getLang() == "ar-SA" ? ar.firstName_required : en.firstName_required),
            "lastName": Yup.string().required(getLang() == "ar-SA" ? ar.lastName_required : en.lastName_required),
            "mobile": Yup.string().required(getLang() == "ar-SA" ? ar.mobile_required : en.mobile_required),
            "email": Yup.string().required(getLang() == "ar-SA" ? ar.email_required : en.email_required),
            "password": Yup.string().required(getLang() == "ar-SA" ? ar.password_required : en.password_required)
        },
        myProfile: {
            "firstName": Yup.string().required(),
            "lastName":Yup.string().required(),
            "email":Yup.string().email().required(),
            "mobile": Yup.string().required(getLang() == "ar-SA" ? ar.mobile_required : en.mobile_required),
        },
        changePassword: {
            "currentPassword": Yup.string().required(getLang() == "ar-SA" ? ar.currentPasswordRequired : en.currentPasswordRequired),
            "newPassword": Yup.string().required(getLang() == "ar-SA" ? ar.newPasswordRequired : en.newPasswordRequired),
            "confirmPassword": Yup.string().required(getLang() == "ar-SA" ? ar.confirmPasswordRequired : en.confirmPasswordRequired)
                .oneOf([Yup.ref('newPassword')], getLang() == "ar-SA" ? ar.confirmPasswordMatch : en.confirmPasswordMatch)
        },
        newsTeller: {
            "email":Yup.string().email().required()
        },
        contactUs: {
            "name": Yup.string().required(),
            "email":Yup.string().email().required(),
            "mobile":Yup.string().required(),
            "company":Yup.string().required(),
            "website": Yup.string().required(),
            "message":Yup.string().required(),

        }
}

};

export const initialValues = (dataSource: any) => {
    return {
        login: { email: '', password: '', userName: '' },
        register :{email:'',password:"",firstName:'',lastName:'',mobile:''},
        changePassword: {
            "currentPassword": "",
            "newPassword": "",
            "confirmPassword": "",

        },
        myProfile: {
            "firstName": dataSource?.firstName??null,
            "lastName":dataSource?.lastName?? null,
            "email":dataSource?.email?? null,
            "mobile":dataSource?.mobile??null,
            "gender":dataSource?.gender?? false,
            "dateOfBirth": dataSource?.dateOfBirth?parseISO(dataSource?.dateOfBirth):null,
            "profileImage":dataSource?.profileImage??null,

        },
        contactUs: {
            "name": "",
            "email":"",
            "mobile":"",
            "company":"",
            "website": "",
            "message":"",

        },
        newsTeller: {
            "email":''
        }
    }
};

export const formikSetup = (
    initialValues: (data: object) => object,
    sourceOfIntailData: object | undefined, //could be undefined
    validationSchema: () => object,
    pageName: string,
    submitFunction: any
) => {
    return {
        enableReinitialize: true,
        initialValues: {
            //@ts-ignore
            ...initialValues(sourceOfIntailData)[pageName],
        },
        //@ts-ignore
        validationSchema: Yup.object({ ...validationSchema()[pageName] }),
        onSubmit: submitFunction,
    };
};
