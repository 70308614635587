import axiosCall from "../axios/axiosConfig";
import { endpoints } from "../endpoints";

export const listingReservations = ({ pageNumber = 1, pageSize = 10 }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.reservations.listing, {
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchText: "str",
        orderBy: "str",
        orderDirection: "str",
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            data: res?.data?.data || res?.data?.obj,
            totalRows: res?.data?.totalRowsCount,
          });
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const detailsReservations = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.reservations.details, { id: id })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
