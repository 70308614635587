import { useTranslation } from "react-i18next"

export const FeaturesSection = () => {
    const { t } = useTranslation()
    return <section className="row_am features_section" id="features">
        <div className="container">
            <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                <h2><span>{t("Features")}</span> {t("features_title")}</h2>
                <p>{t("features_quote1")} <br />{t("features_quote2")} </p>
            </div>
            <div className="feature_detail">
                <div className="left_data feature_box">

                    <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                        <div className="icon">
                            <img src="images/features-icon-2.png" alt="image" />
                        </div>
                        <div className="text">
                            <h4>{t("easy_secure")}</h4>
                            <p>{t("easy_secure_quote")}</p>
                        </div>
                    </div>

                    <div className="data_block" data-aos="fade-right" data-aos-duration="1500">
                        <div className="icon">
                            <img src="images/features-icon-4.png" alt="image" />
                        </div>
                        <div className="text">
                            <h4>{t("easy_integration")}</h4>
                            <p>{t("easy_integration_quote")}</p>
                        </div>
                    </div>
                </div>

                <div className="right_data feature_box">

                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                        <div className="icon">
                            <img src="images/features-icon-1.png" alt="image" />
                        </div>
                        <div className="text">
                            <h4>{t("table_reservation")}</h4>
                            <p>{t("table_reservation_quote")}</p>
                        </div>
                    </div>

                    <div className="data_block" data-aos="fade-left" data-aos-duration="1500">
                        <div className="icon">
                            <img src="images/features-icon-3.png" alt="image" />
                        </div>
                        <div className="text">
                            <h4>{t("customer_review")}</h4>
                            <p>{t("customer_review_quote")}</p>
                        </div>
                    </div>

                </div>
                <div className="feature_img" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                    <img src="/images/features_frame.png" alt="image" />
                </div>
            </div>
        </div>
    </section>
}