import { useTranslation } from "react-i18next"

export const PricingSection = () => {
    const { t } = useTranslation()
    return <section className="row_am pricing_section" id="pricing">
        <div className="container">
            <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                <h2><span>{t("pricing")}</span></h2>

            </div>
            {/*          <div className="toggle_block" data-aos="fade-up" data-aos-duration="1500">
                <span className="month active">Monthly</span>
                <div className="tog_block">
                    <span className="tog_btn"></span>
                </div>
                <span className="years">Yearly</span>
                <span className="offer">50% off</span>
            </div> */}

            <div className="pricing_pannel monthly_plan active" data-aos="fade-up" data-aos-duration="1500">
                <div className="row">
                    <div className="col-md-4">
                        <div className="pricing_block">
                            <div className="icon">
                                <img src="/images/features-icon-1.png" alt="image" />
                            </div>
                            <div className="pkg_name">
                                <h3>{t("table_reservation")}</h3>
                                <span>{t("module")}</span>
                            </div>
                            <span className="price"> 15 SAR</span>
                            <ul className="benifits">
                                <li>
                                    <p>{t("Up to 25 channels")}</p>
                                </li>
                                <li>
                                    <p>{t("unlimited Bookings")}</p>
                                </li>
                                <li>
                                    <p>{t("Up to 20 Branch")}</p>
                                </li>
                                <li>
                                    <p>{t("2 Domains access")}</p>
                                </li>
                                <li>
                                    <p>{t("Support on request")}</p>
                                </li>
                            </ul>
                            <a href="#" className="btn white_btn">{t("BUY NOW")}</a>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="pricing_block highlited_block">
                            <div className="icon">
                                <img src="/images/features-icon-2.png" alt="image" />
                            </div>
                            <div className="pkg_name">
                                <h3>{t("payment")}</h3>
                                <span>{t("module")}</span>
                            </div>
                            <span className="price">99 SAR</span>
                            <ul className="benifits">
                                <li>
                                    <p>{t("10 Payment Methods")}</p>
                                </li>
                                <li>
                                    <p>{t("unlimited Bookings")}</p>
                                </li>
                                <li>
                                    <p>{t("Up to 20 Branch")}</p>
                                </li>
                                <li>
                                    <p>{t("2 Domains access")}</p>
                                </li>
                                <li>
                                    <p>{t("Support on request")}</p>
                                </li>
                            </ul>
                            <a href="#" className="btn white_btn">{t("BUY NOW")}</a>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="pricing_block">
                            <div className="icon">
                                <img src="/images/features-icon-3.png" alt="image" />
                            </div>
                            <div className="pkg_name">
                                <h3>{t("Customers Review")}</h3>
                                <span>{t("module")}</span>
                            </div>
                            <span className="price">55 SAR</span>
                            <ul className="benifits">
                                <li>
                                    <p>{t("4 Review channels output")}</p>
                                </li>
                                <li>
                                    <p>{t("unlimited Reviews")}</p>
                                </li>
                                <li>
                                    <p>{t("Up to 20 Branch")}</p>
                                </li>
                                <li>
                                    <p>{t("2 Domains access")}</p>
                                </li>
                                <li>
                                    <p>{t("Support on request")}</p>
                                </li>
                            </ul>
                            <a href="#" className="btn white_btn">{t("BUY NOW")}</a>
                        </div>
                    </div>
                </div>
            </div>

            <p className="contact_text" data-aos="fade-up" data-aos-duration="1500">{t("Not sure what to choose ?")} <a href="/contactus">{t("contact us")}</a> {t("for custom packages")}</p>
        </div>
    </section>
}